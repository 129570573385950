import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import LanguageIcon from '@mui/icons-material/Language';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import PaymentIcon from '@mui/icons-material/Payment';

const sidebarConfig = [
  {
    title: 'DASHBOARD',
    path: '/app',
    icon: <DashboardIcon/>,
	color: "#FFFFFF"
  },
  {
    title: 'COMPANY',
    path: '/company',
    icon: <BusinessIcon/>
  },
  {
	title: 'SITES/APPS/SplitPay-s',
	path: '/sites',
	icon: <LanguageIcon/>,
	company_required: true
  },
  {
	title: 'PAYMENT METHODS',
	path: '/paymentmethods',
	icon: <PaymentIcon/>,
	company_required: true,
	site_required: true
  },
  {
	title: 'FISCAL/INTEGRATION MODULES',
	path: '/fiscalmethods',
	icon: <PaymentIcon/>,
	company_required: true,
	site_required: true
  },
  {
	title: 'SHIPPING METHODS',
	path: '/shippingmethods',
	icon: <PaymentIcon/>,
	company_required: true,
	site_required: true
  },
  {
	title:"SUBSCRIPTIONS",
	path: '/subscriptions',
	icon: <SubscriptionsIcon/>,
	company_required: true,
	site_required: true
  },
  {
	title:"ORDERS",
	path: '/orders',
	icon: <SubscriptionsIcon/>,
	company_required: true,
	site_required: true
  },
  {
	title:"TRANSACTIONS",
	path: '/transactions',
	icon: <AccountBalanceIcon/>,
	company_required: true,
	site_required: true
  },
  {
    title: 'PLATFORM MODULES',
    path: '/platform',
    icon: <ViewModuleIcon/>
  },
  {
    title: 'SUPPORT TICKETS',
    path: '/tickets',
    icon: <AirplaneTicketIcon/>
  },
  {
    title: 'HELP',
    path: '/help',
    icon: <LiveHelpIcon/>
  }
];

export default sidebarConfig;
