

import { Switch, Route } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
// import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
//import Products from './pages/Products';
//import Blog from './pages/Blog';

import User from './pages/User';
import Company from './pages/Company';
import Help from './pages/Help';
import Platform from './pages/Platform';
import Sites from './pages/Sites';
import AuthN from './pages/AuthN';

import Subscriptions from './pages/Subscriptions';
import Transactions from './pages/Transactions';
import Orders from './pages/Orders';

import Tickets from './pages/Tickets';
import CreateCompany from './pages/CreateCompany';

import CompanyDetails from './pages/CompanyDetails';
import SiteDetails from './pages/SiteDetails';

//import SubscriptionsDetails from './pages/SubscriptionsDetails';
//import TransactionsDetails from './pages/TransactionsDetails';
//import OrdersDetails from './pages/OrdersDetails';

import PaymentMethods from './pages/PaymentMethods';
import ShippingMethods from './pages/ShippingMethods';
import FiscalMethods from './pages/FiscalMethods';

import CreatePayment from './pages/CreatePayment';
import CreateShipping from './pages/CreateShipping';
import CreateFiscal from './pages/CreateFiscal';

import PaymentDetails from './pages/PaymentDetails';
import ShippingDetails from './pages/ShippingDetails';
import FiscalDetails from './pages/FiscalDetails';

//import Localization from './pages/Localization'
//import CreateLocalization from './pages/CreateLocalization'
//import LocalizationDetails from './pages/LocalizationDetails'
import TicketDetails from './pages/TicketDetails'
import NewTicket from './pages/NewTicket'

import Wait from './layouts/Wait'

export default function Router() {
  return (
    <Switch>
      <Route path="/" component={Wait} exact />
      <Route path="/register" component={Register} exact />
      <Route path="/login" component={Login} exact />
	  <Route path="/authn/:authtype?/:reftoken?/:lang?" component={AuthN} exact />
	  <Route path="/wait" component={Wait} exact />
	  
	  <DashboardLayout>
        <Route path="/app" component={DashboardApp} exact />
        
		<Route path="/companydetails/:id" component={CompanyDetails} exact />
        <Route path="/company" component={Company} exact />
        <Route path="/newcompany" component={CreateCompany} exact />
		
        <Route path="/help" component={Help} exact />
        <Route path="/tickets" component={Tickets} exact />
        
        <Route path="/platform" component={Platform} exact />
        <Route path="/sites" component={Sites} exact />
		
        <Route path="/subscriptions/:id?" component={Subscriptions} exact />
		<Route path="/orders/:id?" component={Orders} exact />
        <Route path="/transactions/:id?" component={Transactions} exact />
		
		<Route path="/user" component={User} exact />
        {/*<Route path="/products" component={Products} exact />*/}
		
        {/*<Route path="/blog" component={Blog} exact />*/}
        
		<Route path="/paymentmethods" component={PaymentMethods} exact />
		<Route path="/shippingmethods" component={ShippingMethods} exact />
		<Route path="/fiscalmethods" component={FiscalMethods} exact />
		
		<Route path="/sitedetails/:id" component={SiteDetails} exact />
		
		{/*
        <Route path="/subscriptionsdetails/:id" component={SubscriptionsDetails} exact />
		<Route path="/ordersdetails/:id" component={OrdersDetails} exact />
        <Route path="/transactionsdetails/:id" component={TransactionsDetails} exact />
		*/}
        
		<Route path="/newpayment" component={CreatePayment} exact />
		<Route path="/newshipping" component={CreateShipping} exact />
		<Route path="/newfiscal" component={CreateFiscal} exact />
        
		<Route path="/paymentdetails/:id" component={PaymentDetails} exact />
		<Route path="/shippingdetails/:id" component={ShippingDetails} exact />
		<Route path="/fiscaldetails/:id" component={FiscalDetails} exact />
		
        {/*<Route path="/localization/:id" component={Localization} exact />*/}
        {/*<Route path="/createLocalization/:id" component={CreateLocalization} exact />*/}
		{/*<Route path="/localizationDetails/:id" component={LocalizationDetails} exact />*/}
        <Route path="/ticketdetails/:id" component={TicketDetails} exact />
        <Route path="/newticket" component={NewTicket} exact />
        {/* <Route path="*" component={NotFound} /> */}

      </DashboardLayout>
    </Switch>
  )
}