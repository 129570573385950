import * as Yup from 'yup';

import { forwardRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import { useHistory } from 'react-router-dom';
import { Stack, TextField, IconButton, InputAdornment, Typography, Button, Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import configData from "../../../config.json";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import FingerprintAppIcon from '@mui/icons-material/Fingerprint';
import { useHistory } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SendIcon from '@mui/icons-material/Send';


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function RegisterForm() {
  const history = useHistory();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmpassword, setshowconfirmpassword] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("success");
  const [AlertType, setAlertType] = useState("success");
  const [open, setOpen] = useState(false);
  const [addWebAuthN, setAddWebAuthN] = useState(true);
  
  const [verifyPIN, setVerifyPIN] = useState(false);
  const [PIN, setPIN] = useState(["","","","","",""]);
  
  const [pendingData, setPendingData] = useState(null);
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const LABELS = {
	INPUT_INSUFFICIENT: t("Please correct your input"),
    PASSWORD_NOT_ACCEPTABLE	: t("Password must meet complexity requirements"),
	PASSWORDS_MISMATCH: t("Password confirmation mismatch"),
	INVALID_EMAIL: t("Bad email address"),
	INVALID_NAME: t("Bad first or last name"),
	SERVICE_UNAVALABLE: t("Service is currently unavailable. Please try later..."),
	NOT_FOUND: t("Handshake session expired or not found"), 
	EMAIL_EXISTS: t("Specified email already exists"),
    DATA_MISMATCH: t("Data mismatch! Please retry registration from beginning!"),
	TEMPORARILY_BLOCKED:t("You are temporarily blocked becuse of lot of request is a short time period. Try in a few minutes!")	
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t('too short?'))
      .max(50, t('too long?'))
      .required(t('first name is required')),
    lastName: Yup.string().min(2, t('too short?')).max(50, t('too long?')).required(t('last name is required')),
    email: Yup.string().email('please input valid email address').required(t('email is required')),
    password: Yup.string().required(t('password is required')),
    confirmpassword: Yup.string().required(t('confrimation of password is required'))
  });
  
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmpassword: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, e) => {
		
	  let data = {
			Name: values.firstName + " " + values.lastName,
			Email: values.email,
			Password: values.password,
			Confirmpassword: values.confirmpassword
		};
		
	  setPendingData(data);
	  
	  registerAccountRequest(data);
    }
  });
  
  const registerAccountRequest = (data) => {
	  
	  data.language = sessionStorage.language;
	  
	  setIsSubmitting(true);
	  
	  fetch(configData.API_URL + 'register', {
		method: "POST",  
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data)
	  }).then(raw_response => {
		  
		  return raw_response.json();
		  
	  }).then(response => {
		  
		 setIsSubmitting(false);
		  
		 if(response.error){
			setAlertMessage(LABELS[response.error] ? LABELS[response.error] : response.error);
            setAlertType("error");
            setOpen(true);
		 }else{
			if(response.email_verify){
				
				setAlertMessage(t("Account data is accepted. You must verify your email address before we finalize you account creation. Please check you email for security code we sent you."));
				setAlertType("success");
				setOpen(true);
				setVerifyPIN(true);
				
				try{
					document.getElementById("PIN1").focus();
				}catch(pex){}
				
			}else if(response.authorization){
				const user_data = response.authorization.user;
				const authToken = response.authorization.authToken;
				sessionStorage.UserData = JSON.stringify(user_data);
				sessionStorage.AccessToken = JSON.stringify(authToken);
				if(response.persistant_verification){
					localStorage["hpayverifiedbrowser"] = response.persistant_verification;
				}
				if(addWebAuthN){
					history.push('/user?createwebauthn=1');	
				}else{
					if(sessionStorage.addsite){
						history.push('/sites');
					}else{
						history.push('/app');
					}
				}
			}else{
				setAlertMessage(t("Bad response please retry or try later..."));
				setAlertType("error");
				setOpen(true);
			}
		 }
	    }).catch(error => {
			setIsSubmitting(false);
			setAlertMessage(t("Error! Please try again or later..."));
			setAlertType("error");
			setOpen(true);
        });
	  
  }; 
  
  const lastDigit = (val) => {
	  val = String(val).replace(/[^\d]/g,"");
	  if(val == "")
		  return "";
	  if(String(val).length > 1){
		  return String(val).split("").slice(-1)[0];
	  } 
	  return String(val);
  };
  
  const setPinChar = (pos, chr) => {
	  let pinCopy = PIN.map(c=>c);
	  pinCopy[pos] = chr;
	  setPIN(pinCopy);
	  if(chr !== ""){
		  if(document.getElementById("PIN" + (pos + 2))){
			  document.getElementById("PIN" + (pos + 2)).focus();
		  }
	  }
	  
	  if(window.pinregsubmithandle){
		  clearTimeout(window.pinregsubmithandle);
		  window.pinregsubmithandle = null;
	  }
	  window.pinregsubmithandle = setTimeout(checkPINsubmit,400);
  };
  
  const checkPINsubmit = () => {
	  let pin_input = ["","","","","",""];
	  for(var i = 1; i <= 6; i++){
		  pin_input[i - 1] = document.getElementById("PIN" + i).value;
	  }
	  
	  if(!pin_input.filter(c => c === "").length){
		  let data = Object.assign({}, pendingData);
		  data.EmailVerification = pin_input.join("");
		  registerAccountRequest(data); 
	  }
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={AlertType} sx={{ width: '100%' }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
		<Typography variant='h7' style={{color:"gray", fontWeight:"bold"}}>
			{t("Account data")}
		</Typography>
		
		{verifyPIN ? 
		<Stack spacing={3} marginTop={3}>
			<Typography variant='h7' style={{fontWeight:"bold"}}>
			{t("E-mail address verification pending...")}
		    </Typography>
			
			<Typography variant='p' style={{color:"gray", fontSize:"80%", textAlign:"justify"}}>
			{t("Please check your mailbox for 6-digit security code we sent you. Don't forget to check SPAM/Trash/Junk folders also if you don't find a mail message containing security code in your primary inbox.")}
		    </Typography>
			
			<Typography variant='h6' >
			{t("SECURITY CODE")}
		    </Typography>
			
			<Stack direction={{ xs: 'row'}} spacing={3}>
			
			<TextField
			  label=""
			  id="PIN1"
			  disabled={isSubmitting}
			  type="text"
			  value={PIN[0]}
			  
			  inputProps={{ style:{textAlign:"center", fontSize:"1.7em", padding:"12px 6px"}}}
			  onInput={(e) => {
				  setPinChar(0,lastDigit(e.target.value))
			  }}
			  onPaste={(e) => {
				  
				  e.stopPropagation();
				  e.preventDefault();

				  let clipboardData = e.clipboardData || window.clipboardData;
				  let pastedData = String(clipboardData.getData('Text')).trim().replace(/[^\d]/g,"");

				  if(String(pastedData).length == PIN.length){
					  
					  String(pastedData).split("").forEach((c,ind) =>{
						  document.getElementById("PIN" + (ind + 1)).value = c;
					  });
					  
					  setPIN(String(pastedData).split(""));
					  
					  let data = Object.assign({}, pendingData);
					  data.EmailVerification = pastedData;
					  
					  if(window.pinregsubmithandle){
						  clearTimeout(window.pinregsubmithandle);
						  window.pinregsubmithandle = null;
					  }
					  
					  window.pinregsubmithandle = setTimeout(checkPINsubmit,400);
					  
					  
				  }
				  
			  }}
            />
			
			<TextField
              label=""
			  id="PIN2"
			  disabled={isSubmitting}
			  type="text"
			  value={PIN[1]}
			  style={{textAlign:"center", fontSize:"20px"}}
			  inputProps={{ style:{textAlign:"center", fontSize:"1.7em", padding:"12px 6px"}}}
			  onInput={(e) => {
				  setPinChar(1,lastDigit(e.target.value))
			  }}
            />
			
			<TextField
              label=""
			  id="PIN3"
			  disabled={isSubmitting}
			  type="text"
			  value={PIN[2]}
			  inputProps={{ style:{textAlign:"center", fontSize:"1.7em", padding:"12px 6px"}}}
			  onInput={(e) => {
				  setPinChar(2,lastDigit(e.target.value))
			  }}
            />
			
			<TextField
              label=""
			  type="text"
			  id="PIN4"
			  disabled={isSubmitting}
			  value={PIN[3]}
			  inputProps={{ style:{textAlign:"center", fontSize:"1.7em", padding:"12px 6px"}}}
			  onInput={(e) => {
				  setPinChar(3,lastDigit(e.target.value))
			  }}
            />
			
			<TextField
              label=""
			  type="text"
			  id="PIN5"
			  disabled={isSubmitting}
			  value={PIN[4]}
			  inputProps={{ style:{textAlign:"center", fontSize:"1.7em", padding:"12px 6px"}}}
			  onInput={(e) => {
				  setPinChar(4,lastDigit(e.target.value))
			  }}
            />
			
			<TextField
              label=""
			  type="text"
			  id="PIN6"
			  disabled={isSubmitting}
			  value={PIN[5]}
			  inputProps={{ style:{textAlign:"center", fontSize:"1.7em", padding:"12px 6px"}}}
			  onInput={(e) => {
				  setPinChar(5,lastDigit(e.target.value))
			  }}
            />
			
			
			
			</Stack>
			<br/>
			<Button
					variant="dark"
					onClick={(e) => setVerifyPIN(false)}
					startIcon={<ExitToAppIcon />}
					color="secondary"
				>
					{t("Cancel and return to the registration form...")}
			</Button>
			<br/>
			<br/>
		</Stack> : 
	    <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t("First name")}
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label={t("Last name")}
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={t("Email address")}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={t("Password")}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton tabIndex={-1} edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            autoComplete="current-confirmpassword"
            type={showconfirmpassword ? 'text' : 'password'}
            label={t("Repeat password")}
            {...getFieldProps('confirmpassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton tabIndex={-1} edge="end" onClick={() => setshowconfirmpassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmpassword && errors.confirmpassword)}
            helperText={touched.confirmpassword && errors.confirmpassword}
          />
		  
		  <Stack marginTop={0}>
		  <Typography variant='h7' style={{color:"gray", fontWeight:"bold"}}>
			{t("Biometric/WebAuthN Identity Protection")}
		  </Typography>
		  <FormControl component="fieldset" key={"chk-setAddWebAuthN"}>
		    
			<Stack direction="row" style={{fontStyle:"italic", fontSize:"80%"}}>
			<FingerprintAppIcon /> ***{t("Recommended")} 
			</Stack>
			<FormControlLabel key={"chk-setAddWebAuthN"} value={1} onChange={(e) => setAddWebAuthN(e.target.checked ? true : false)} required={true} control={<Checkbox color="secondary" checked={addWebAuthN} />} label={t("Upon registration setup biometric/webauthn credentials")} />
		  </FormControl>
		  </Stack>	  
		  
		  <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t("Register")}
          </LoadingButton>
        </Stack> }
		
		
      </Form>
    </FormikProvider>
  );
}
