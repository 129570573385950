import { Link as RouterLink } from 'react-router-dom';
import Iframe from 'react-iframe'
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { RegisterForm } from '../components/authentication/register';
//import AuthSocialRegister from '../components/authentication/AuthSocialRegister';
import { useTranslation } from 'react-i18next';
import LanguageBar from "../layouts/dashboard/LanguagePopover"


const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function Register() {
  const { t } = useTranslation();
  
  const loadInfoPanels = function(){
	  if(window["info_content_top_" + sessionStorage.language]){
		  if(document.getElementById('info_content_top'))
			document.getElementById('info_content_top').innerHTML = window["info_content_top_" + sessionStorage.language];
	  }else{
		  fetch("./static/landing_top_" + sessionStorage.language + ".html").then(r => {
			  return r.text()	  
		  }).then(html => {
			  if(document.getElementById('info_content_top')){
				window["info_content_top_" + sessionStorage.language] = document.getElementById('info_content_top').innerHTML = html;
			  }
		  }).catch(err =>{
			  
			  
		  });
	  }
	  
	  if(window["info_content_bottom_" + sessionStorage.language]){
		  if(document.getElementById('info_content_bottom'))
			document.getElementById('info_content_bottom').innerHTML = window["info_content_bottom_" + sessionStorage.language];
	  }else{
		  fetch("./static/landing_bottom_" + sessionStorage.language + ".html").then(r => {
			  return r.text()	  
		  }).then(html => {
			  if(document.getElementById('info_content_bottom')){
				window["info_content_bottom_" + sessionStorage.language] = document.getElementById('info_content_bottom').innerHTML = html;
			  }
		  }).catch(err =>{
			  
			  
		  });
	  }
  };
  
  loadInfoPanels();
  document.addEventListener("languange_changed", function(e){
	  loadInfoPanels();
  });

  return (
    <RootStyle className="auth-content" title="Register | Minimal-UI">
      
	  <div className='auth-side-panel'>
		  
			  <AuthLayout>
				<LanguageBar />
				{t("Already have an account?")} &nbsp;
				<Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
				  {t("Login")}
				</Link>
			  </AuthLayout>
			  
		  

		  <MHidden width="mdDown">
			<SectionStyle>
			  <Typography className="register-promo-text" variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
				{t("Seamless site payments | Shipping Automation | Fiscalization")}
			  </Typography>
			  <img alt="register" src="/static/illustrations/illustration_register.png" />
			</SectionStyle>
		  </MHidden>
	  </div>
	  
      <Container  className='auth-form'>
        <ContentStyle>
		{!sessionStorage.addsite ?
          <Box sx={{ mb: 5 }}>
            <div id="info_content_top" />
		</Box>: null}
		  {/*
          <AuthSocialRegister />
		  */}
          
		  <RegisterForm />
		  <br/>
		  {!sessionStorage.addsite ?
          <div id="info_content_bottom" />
		  : null}
          
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
