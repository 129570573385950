import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import { MHidden } from '../../components/@material-extend';
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import NotificationsPopover from './NotificationsPopover';
import configData from "../../config.json";

import SelectSite from '../../pages/SelectSite'

import LanguagePopover from './LanguagePopover'
import jsonServerProvider from 'ra-data-json-server';
import { forwardRef, useEffect, useState } from 'react';
import { fetchUtils } from 'react-admin';
import { useTranslation } from 'react-i18next';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: " #021420",
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
   const { t } = useTranslation();	
   //const [sites, setsites] = useState([]);	
   const [AlertMessage, setAlertMessage] = useState("success");	
   const [AlertType, setAlertType] = useState("success");
   const [AlertOpen, setAlertOpen] = useState(false);
  
   const httpClient = (url, options = {}) => {
		if (!options.headers) {
		  options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
		}
		const token = JSON.parse(sessionStorage.AccessToken).Token;
		options.headers.set('Authorization', `${token}`);
		return fetchUtils.fetchJson(url, options);
   };
   const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
   
   const loadData = (params) => {
	   
	   
	   
	
	   
	/*   
	   
    if (sessionStorage.company_id) {
      dataProvider.getList("company_sites", { pagination: { page: 1, perPage: 9999 }, sort: { field: 'url', order: 'ASC' }, filter: { CompanyId: sessionStorage.company_id } })
        .then(res => {
          const data = res.data;
          const res_data = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].CompanyId == sessionStorage.company_id) {
              res_data.push({
                Id: data[i].id,
                siteurl: data[i].Url,
                siteurls: data[i].Urls,
                companyname: JSON.parse(sessionStorage.CurrentCompany).name,
                date: data[i].CreatedAt,
              })
            }
          }
          if (res_data.length === 0) {
            sessionStorage.CurrentSite = JSON.stringify({ id: "", name: t("--not selected--") });
			sessionStorage.site_id = "";
			
            setAlertMessage(t("Please add a POS."));
            setAlertType("info");
            setAlertOpen(true);
          }
          else if (params === "default") {
            sessionStorage.CurrentSite = JSON.stringify({ id: res_data[0].Id, name: res_data[0].siteurl });
			sessionStorage.site_id = res_data[0].Id;
          }
          else if (res_data.length === 1) {
            sessionStorage.CurrentSite = JSON.stringify({ id: res_data[0].Id, name: res_data[0].siteurl });
			sessionStorage.site_id = res_data[0].Id;
          }
          setsites(res_data);
		  onOpenSidebar();
		  
        });
    }
    else {
      setAlertMessage(t("Please select a company"));
      setAlertType("info");
      setAlertOpen(true);
    }
	*/
  };	
	
  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'common.white' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
		
		<SelectSite reload={loadData} />
        
		{/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />
		
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <LanguagePopover />
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
