const hpbtoa = (str) => {
				return btoa(String(str || "").split("").map(c => c.charCodeAt(0)).join(","));	
			};
const hpatob = (strb64) => {
				return atob(strb64 || "").split(",").map(ccode => String.fromCharCode(ccode)).join("");
			};			

const definition = {
	hpbtoa,
    hpatob
};

export default definition;