import Page from '../components/Page';
import { Stack, Typography, CardActionArea, TextField, Container, Button, Icon } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SendIcon from '@mui/icons-material/Send';
import configData from "../config.json";
import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//import SelectSite from './SelectSite' 

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export default function CreateTicket() {
  const history = useHistory();
  const { t } = useTranslation();
  const [TicketName, setTicketName] = useState("");
  
  const [Company, setCompany] = useState("");
  const [Site, setSite] = useState("");
  
  
  const [AlertMessage, setAlertMessage] = useState("success");
  const [AlertType, setAlertType] = useState("success");
  const [AlertOpen, setAlertOpen] = useState(false);

  const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
    }
    const token = JSON.parse(sessionStorage.AccessToken).Token;
    options.headers.set('Authorization', `${token}`);
    return fetchUtils.fetchJson(url, options);
  };

  const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
  const MainUserId = JSON.parse(sessionStorage.AccessToken).UserId
  const Email = JSON.parse(sessionStorage.UserData).Email;
  const [AltEmails, setAltEmails] = useState("");
  
  const validateEmail = (email) => {
	  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email).toLowerCase());
	};
  
  const saveticket = (params) => {
	  
	let emls = [];
	if(AltEmails.trim()){
		emls = AltEmails.split(",").map(function(eml){
			
			if(!validateEmail(eml))
				return false;
			
			return eml.trim();
			
		}).filter(eml => eml);
	}  
	  
    dataProvider.create('tickets', { data: { UserId: MainUserId, CompanySiteId: sessionStorage.site_id || null, CompanyId: sessionStorage.company_id || null, Title: TicketName } })
      .then(res => {
		if(res.data.id){  
			history.push('ticketdetails/' + res.data.id);
		}else{
			setAlertMessage(t("Error during support ticket creation") + "!");	
		    setAlertType("error");
		    setAlertOpen(true);
		}
      })
      .catch(error => {
		  if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
				let event = new Event("hpay-auth-expired");
				window.dispatchEvent(event);
			}
			
		  setAlertMessage(t("Error during support ticket creation") + "!");	
		  setAlertType("error");
		  setAlertOpen(true);
					
        console.log(error)
      })
  };
  
  
  const back = (params) => {
    history.push('tickets')
  };
  
  const setCompanyAndSite = () => {
		setCompany(sessionStorage.CurrentCompany ? JSON.parse(sessionStorage.CurrentCompany).name : t('--not selected--'));
		setSite(sessionStorage.CurrentSite ? JSON.parse(sessionStorage.CurrentSite || "{}").name : t('--not selected--'));
  };
  
  
  useEffect(() => {
    
	
	setCompanyAndSite();
	
	window.addEventListener("hpay-site-changed", setCompanyAndSite, false);
	return () => {
		window.removeEventListener("hpay-site-changed", setCompanyAndSite, false);
	};
	
    // eslint-disable-next-line
  }, [])
  
 
  
  return (
    <Page title=" Ticket | Holest">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" >
            {t("Raise a new support ticket")} | {Email}
          </Typography>
          
		  {/*<SelectSite reload={load} />*/}
          
		  <Button
				variant="dark"
				onClick={(e) => back()}
				startIcon={<ExitToAppIcon />}
				color="secondary"
			>
				{t("Back to support tickets list...")}
		  </Button>
					
        </Stack>
		<Stack direction="column" alignItems="center" justifyContent="space-between" mb={5}>
		  <Typography variant="p" >
		   {t("Support tickets are way to make sure our support receives your support request (since email can be unreliable sometimes). Please allow our team at least 24h to respond.")}
          </Typography>
		  <Typography variant="p" >
		   {t("You will be able to describe your problem after ticket creation.")}
          </Typography>
		</Stack>
        <Grid container>
          <Grid item xs={12} justifyContent="center">
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <TextField fullWidth autoFocus onChange={(e) => setTicketName(e.target.value)} id="name" label={t("Please name your issue")} type="text" style = {{paddingBottom : "20px"}} />
					
					<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
						<TextField
						  fullWidth
						  label={t("Concerning Company")}
						  value={Company}
						  disabled
						/>
						
						<TextField
						  fullWidth
						  label={t("Concerning POS")}
						  value={Site}
						  disabled
						/>
						
					</Stack>
					
					<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={5}>
                      
					  <Button
					    disabled={TicketName.trim().length < 6}
                        fullWidth
                        size="large"
                        type="button"
                        onClick={(e) => saveticket()}
                        endIcon={<SendIcon />}
                        variant="contained"
                      >
                        {t("Create")}
                      </Button>
                      
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>

  );
}
