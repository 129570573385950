import { useRef, useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import MenuPopover from '../../components/MenuPopover';
import { useTranslation } from 'react-i18next';
import HPAY_TRANSLATION from "../../i18n_translation";
import queryString from 'query-string';
import { useLocation } from 'react-router';

export default function LanguagePopover() {
  const { i18n, t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  // const [index, setindex] = useState(0);
  
  sessionStorage.language = i18n.language;
  window.HPAY_TRANSLATION = HPAY_TRANSLATION;
  
  
  const handleOpen = () => {
	setOpen(true);
  };
  
  const languagechange = (params) => {
    setOpen(false);
    i18n.changeLanguage(params);
	sessionStorage.language = i18n.language;
	localStorage.hpay_languange = i18n.language;
	
	let languagechange_event = new CustomEvent("languange_changed",{ language: sessionStorage.language});
	document.dispatchEvent(languagechange_event);
  };
  
  if(queryString.parse(location.search).lang){
	  let setlng = queryString.parse(location.search).lang;
	  if(HPAY_TRANSLATION.languages.indexOf(setlng) > -1){
		localStorage.hpay_languange = queryString.parse(location.search).lang.trim();
	  }
  }
 
  if(localStorage.hpay_languange){
	  if(localStorage.hpay_languange != sessionStorage.language){
		  if(HPAY_TRANSLATION[localStorage.hpay_languange]){
			  setTimeout(function(){
				i18n.changeLanguage(localStorage.hpay_languange);  
			  },500);
		  }
	  }
  }

  const handleClose = () => {
    setOpen(false);
  };
  
  useEffect(() => {
    
	fetch("/static/translation.json").then(t => t.json()).then( translation => {
		for(let lng in translation){
			if(translation.hasOwnProperty(lng)){
				for(let phrase in translation[lng].translation){
					if(HPAY_TRANSLATION[lng] && HPAY_TRANSLATION[lng].translation){
						HPAY_TRANSLATION[lng].translation[phrase] = translation[lng].translation[phrase];
					}
				}
			}
		}
	}).catch(err => console.error(err));
	
  }, [])

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <img src={'/static/icons/' + (i18n.language || "en").substring(0,2) + '.png'} alt={t("__LANGUAGE__",{lng:i18n.language})} />
      </IconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {HPAY_TRANSLATION.languages.map((lng) => (
            <MenuItem
              key={lng}
              selected={lng === i18n.language}
              onClick={(e) => languagechange(lng)}
              // onChange = {}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Box component="img" alt={t("__LANGUAGE__",{lng:lng})} src={'/static/icons/' + lng.substring(0,2) + '.png'} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {t("__LANGUAGE__",{lng:lng})}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
