import Iframe from 'react-iframe'
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, forwardRef } from 'react';
import LanguageBar from "../layouts/dashboard/LanguagePopover";
import { useParams } from 'react-router';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { deviceType, osName, browserName } from 'react-device-detect';
import configData from "../config.json";
import { io } from 'socket.io-client';


const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AuthN() {
  const { t, i18n } = useTranslation();
  const params = useParams();
  
  const [AlertMessage, setAlertMessage] = useState("success");
  const [AlertType, setAlertType] = useState("success");
  const [AlertOpen, setAlertOpen] = useState(false);
  
  const [showClose, setShowClose] = useState(false);
  
  const base64encode = (arrayBuffer) => {
		if (!arrayBuffer || arrayBuffer.length == 0)
			return undefined;
		return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)));
  };

  const arrayBufferToString = (arrayBuffer) => {
		return String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
  };

  const stringToArrayBuffer = (str) => {
		return Uint8Array.from(str, c => c.charCodeAt(0)).buffer;
  };
  
  const AlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  
  const [mainMessage, setMainMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  
  const getChallange = () => {
	  window.verfy_socket.emit("getWebauthNAssertChallenge",{t:params.authtype}, function(resp){
		  if(params.authtype == "c"){
			  
			  let createCredentialDefaultArgs = {
				  publicKey: {
					rp: {
					  name: t("HolestPay") + (/sandbox/.test(configData.API_URL) ? " sandbox" : "") + deviceType + "-" + osName + "-" + browserName,
					  icon: "https://pay.holest.com/static/logo.png"
					},
					user: {
					  id: stringToArrayBuffer(String(parseInt(resp.user_id))),
					  name: resp.email,
					  displayName: resp.name,
					  icon: "https://pay.holest.com/static/logo.png"
					},
					pubKeyCredParams: [
					  {
						alg: -7,
						type: "public-key",
					  },
					  {
						alg: -257,
						type: "public-key",
					  }
					],
					authenticatorSelection: {
						//Select authenticators that support username-less flows
						//requireResidentKey: true,//WILL SHOW QR
						//Select authenticators that have a second factor (e.g. PIN, Bio)
						//userVerification: "required",
						//authenticatorAttachment: "platform"
					},
					excludeCredentials: [],
					attestation: "none",
					timeout: 360000,
					challenge: stringToArrayBuffer(resp.challenge)
				  },
				};
				
				let verificatinonuid = osName + "-" + browserName + "-" + (new Date()).toISOString().substring(0,22).replace(/[^\d]/g,'');
				
				navigator.credentials
				  .create(createCredentialDefaultArgs)
				  .then((cred) => {
					  
					if(cred.response && cred.response.attestationObject){
						let attestation = {
							id: base64encode(cred.rawId),
							clientDataJSON: arrayBufferToString(cred.response.clientDataJSON),
							attestationObject: base64encode(cred.response.attestationObject)
						};
						
						window.verfy_socket.emit("webauthStoreCredentials", { 
							t:params.authtype,
							verificationuid: verificatinonuid,
							verificationdata: {
								deviceType, 
								osName, 
								browserName
							},
							attestation: attestation
						}, function(store_resp){
							
							if(store_resp.status == "OK"){
								setMainMessage(t("External-device identity verification completed!"));	
							}else{
								setErrorMessage(t("Could not complete identity verification over external-device"));
							}
							
							setShowClose(true);
							
							
						});
					}else{
						setAlertMessage(t("Could not obtain credentials!"));
						setAlertType("error");
						setAlertOpen(true);
					}
				  }).catch(err => {
					  setAlertMessage(t("Could not obtain credentials!"));
					  setAlertType("error");
					  setAlertOpen(true);
				  });
			  
			  
		  }else if(params.authtype == "a"){
			  
			  
			  let verificatinonuid = osName + "-" + browserName + "-" + (new Date()).toISOString().substring(0,22).replace(/[^\d]/g,'');
			  
			  let getAssertionOptions = {
						allowCredentials: resp.IDS.reverse().map(credentialId => { return {
																type: "public-key",
																id: Uint8Array.from(atob(credentialId), c=>c.charCodeAt(0)).buffer
															 }}),
						challenge: stringToArrayBuffer(resp.challenge),
						timeout: 180000
					};
			  
			  navigator.credentials.get({
							publicKey: getAssertionOptions
						}).then(rawAssertion => {
							
							let assertion = {
								id: base64encode(rawAssertion.rawId),
								clientDataJSON: arrayBufferToString(rawAssertion.response.clientDataJSON),
								userHandle: base64encode(rawAssertion.response.userHandle),
								signature: base64encode(rawAssertion.response.signature),
								authenticatorData: base64encode(rawAssertion.response.authenticatorData)
							};
							
							window.verfy_socket.emit("webauthAssert", {verificationuid: verificatinonuid , assertion: assertion}, function(assert_resp){
								if(assert_resp.error){
									 setAlertMessage(t("Biometric/WebAuthN verification failed. You can try other verfication method..."));
									 setAlertType("error");
									 setAlertOpen(true);
								}
								
								if(assert_resp.status == "OK"){
									setMainMessage(t("External-device identity verification completed!"));	
								}else{
									setErrorMessage(t("Could not complete identity verification over external-device"));
								}
								
								setShowClose(true);
							});
							
							
			  }).catch(err => {
				  setAlertMessage(t("Could not obtain credentials! Try reverting to verification over e-mailed security code..."));
				  setAlertType("error");
				  setAlertOpen(true);
				  
				  setErrorMessage(t("Could not complete identity verification over external-device"));
				  setShowClose(true); 
			  });
			  
		  }
	  });
  };
  
  const load = async () => {
	if(params.lang){
		if(params.lang != sessionStorage.language){
			i18n.changeLanguage(params.lang);
			sessionStorage.language = params.lang;
			localStorage.hpay_languange = params.lang;
		}
	}else{
		if(!localStorage.country_detected){
			await fetch('https://apps.holest.com/iploc.php?ip=auto').then(res => {
			  const country_name = res.data.CountryName;
			  localStorage.country_detected = country_name;
			  const language = res.data.CountryCode2;
			  if (country_name === "Serbia" || country_name === "BiH" || country_name === "Montenegro") {
				i18n.changeLanguage('rs');
			  }else {
				i18n.changeLanguage('en');
			  }
			  sessionStorage.language = i18n.language;
			})	
		}else{
			const country_name = localStorage.country_detected;
			if (country_name === "Serbia" || country_name === "BiH" || country_name === "Montenegro") {
				i18n.changeLanguage('rs');
			}else {
				i18n.changeLanguage('en');
			}
			sessionStorage.language = i18n.language;
		}  
	}
	
	let is_error = false;
	
	if(params.authtype == "c" && params.reftoken){
		setMainMessage(t("Biometric/WebAuthN credentials approval on external device..."));
	}else if(params.authtype == "a" && params.reftoken){
		setMainMessage(t("Biometric/WebAuthN authorization over an external device..."));
	}else{
		is_error = true;
		setErrorMessage(t("Non-valid/unrecognised request!"));
	}
	
	if(!is_error){
		if(!window.verfy_socket){
			window.verfy_socket = io(configData.API_URL.split("/api")[0],{path: configData.IO_ENDPOINT, query:"refverify=" + params.reftoken + "&external=1"});
			window.verfy_socket.on("connect", function(){
				getChallange();
			});
			
			window.verfy_socket.on("disconnect", function(){
				setErrorMessage(t("Non-valid/unrecognised request!"));
			});
		}else if (!window.verfy_socket.connected){
			window.verfy_socket.connect();
		}else{
			getChallange();
		}
	}
  };
  
  useEffect(() => {
    load();
    // eslint-disable-next-line
  }, [])
  return (
    <RootStyle title="AUTHN | HolestPay">
	  <Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={AlertClose}>
        <Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
	  <Stack direction="column" alignItems="center" justifyContent="space-between" style={{minHeight:"60vh"}}>
		  <div style={{display:"flex", justifyContent:"space-between"}}>
			<img src="/static/logo.svg" alt="AUTHN | HolestPay" style={{maxWidth:"120px"}} />
			<LanguageBar />
		  </div>
		  <Typography variant="p" style={{textAlign:"center"}} gutterBottom>
				  {mainMessage}	
		  </Typography>
		  <Typography variant="p" style={{textAlign:"center", color: "red"}} gutterBottom>
			  {errorMessage}	
		  </Typography>
		  {!showClose ?
			  <img src="/static/fingerprint-security-outline.gif" alt="AUTHN | HolestPay" style={{maxWidth:"120px"}} />
		  : <Button
					fullWidth
					size="large"
					type="submit"
					color={errorMessage ? "error" : "secondary"}
					endIcon={errorMessage ? "" : "✓"}
					variant="contained"
					onClick={(e) => { window.close(); }}
					>
					{t("You can close this window")}
					</Button>}
		  
		  
	  </Stack>
	</RootStyle>
  );
}
