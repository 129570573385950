import { filter } from 'lodash';
import { forwardRef, useState } from 'react';
import { Button, Card, Table, TableRow, TableBody, TableCell, Container, Typography, TableContainer } from '@mui/material';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, CompanyTool } from '../components/_dashboard/user';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { useHistory, Link as RouterLink } from 'react-router-dom';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.Name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SelecetCompany({ handleCloseSelector, loadSites, afterLoad }) {
  // eslint-disable-next-line
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('Name');
  const [filterName, setFilterName] = useState('');
  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = useState(9999);
  const [AlertMessage, setAlertMessage] = useState("success");
  const [AlertType, setAlertType] = useState("success");
  const [AlertOpen, setAlertOpen] = useState(false);
  const [Companydata, setCompanydata] = useState([]);
 
  
  const { t } = useTranslation();
  
  const TABLE_HEAD = [
    //{ id: 'Id', label: t('ID'), alignRight: false },
    { id: 'Name', label: t('Company Name'), alignRight: false },
    { id: 'RegNo', label: t('ResNo'), alignRight: false },
    { id: 'TaxNo', label: t('TaxNo'), alignRight: false },
    { id: 'Address', label: t('Address'), alignRight: false },
  ];

  const AlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = Companydata.map((n) => n.Id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  
  const handleAddCompany = () => {
	  history.push("/newcompany");
	  handleCloseSelector();
  };
  
  const manage = (Id) => {
    
	
    let name = "";
	let role = "";
	
    for (let i = 0; i < Companydata.length; i++) {
      if (Companydata[i].Id === Id) {
        name = Companydata[i].Name;
		role = Companydata[i].CurrentUserRole;
		break
      }
    }
	
	let change = sessionStorage.company_id != Id;
	
    sessionStorage.CurrentCompany = JSON.stringify({ id: Id, name: name })
	sessionStorage.company_id = Id;
	sessionStorage.CurrentUserRole = role;
	
	localStorage.lastCompany = sessionStorage.CurrentCompany;
	localStorage["hpay_last_company_id"] = sessionStorage.company_id;
	
    //loadSites('default');
	
    handleCloseSelector();
	
	if(change){
		let event = new Event("hpay-company-changed");
		window.dispatchEvent(event);
		
		setAlertMessage(t('Please select site...'));
		setAlertType("success");
		setAlertOpen(true);
	}
	
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Companydata.length) : 0;

  const filteredCompanies = applySortFilter(Companydata, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredCompanies.length === 0;
  const loadData = (param) => {
   
	let prev_company_id = sessionStorage.company_id;
	const res_data = Object.values(window.HPAYCompanies || {}).map(c => {
			  
			  let role = "staff";
			 
			  if(c.UserCompanies && c.UserCompanies[0] && c.UserCompanies[0].Role){
				 role = c.UserCompanies[0].Role;
			  }
			  
			  return {
				Id: c.id,
				Name: c.Name,
				RegNo: c.RegNo,
				TaxNo: c.TaxNo,
				Address: c.Address,
				CurrentUserRole: role
			  };
		  });
		  
	if (param === "delete" || res_data.length === 1) {
	  if(sessionStorage.company_id != res_data[0].Id){
		  sessionStorage.CurrentCompany = JSON.stringify({ id: res_data[0].Id, name: res_data[0].Name });
		  sessionStorage.company_id = res_data[0].Id;
		  sessionStorage.CurrentUserRole = res_data[0].CurrentUserRole;
		  localStorage.lastCompany = sessionStorage.CurrentCompany;
		  localStorage["hpay_last_company_id"] = sessionStorage.company_id;
	  }
	}
	
	setCompanydata(res_data);
	
  };
  
  useEffect(() => {
	
    loadData("defalut");
	window.addEventListener("hpay-companies-loaded", loadData, false);
	return () => {
		window.removeEventListener("hpay-companies-loaded", loadData, false);
	};
	
		
    // eslint-disable-next-line  
  }, [])

  return (
    <Container>
      <Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={AlertClose}>
        <Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
          {AlertMessage}
        </Alert>
      </Snackbar>

      <Card style={{ boxShadow: "none" }}>
        <CompanyTool
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={Companydata.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredCompanies
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const { Id, Name, RegNo, TaxNo, Address } = row;
                    const isItemSelected = sessionStorage.company_id == Id;

                    return (
                      <TableRow
                        hover
                        key={Id}
                        tabIndex={-1}
                        onClick={(e) => manage(Id)}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
						className='clickable'

                      >
					  {/* <TableCell align="left">{Id}</TableCell> */}
                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {Name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{RegNo}</TableCell>
                        <TableCell align="left">{TaxNo}</TableCell>
                        <TableCell align="left">{Address}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
				    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
		  
        </Scrollbar>
      </Card>
	  <br/>
	   <Typography variant="paragraph" align="center" paragraph={true}>
            <Button
				variant="dark"
				onClick={handleAddCompany}
				component={RouterLink}
				to="#"
				startIcon={<AddTaskIcon />}
				style={{background:"#f6f7df"}}
				
			  >
				{t("Create new company...")}
			</Button> 
        </Typography>
	  
	  {sessionStorage.__loading_user_companies == 1 ? <div className={"center-child"}><div className={"lds-roller"}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>  : null}
	  
    </Container>
  );
}
