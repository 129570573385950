import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    //textTransform: 'capitalize',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

const ListSubtyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const activeColor = "#e3d8d8";

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);
  // eslint-disable-next-line
  const [display, setdisplay] = useState("display : none");

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          component={RouterLink}
          to={path}
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={t(title)} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* eslint-disable-next-line */}
            {children.map((item) => {
              const { title, path, icon } = item;
              // const isActiveSub = active(path);
              if (path === "/paymentmethods") {
                if (sessionStorage.site_id) {
                  return (
                    <ListSubtyle
                      key={title}
                      component={RouterLink}
                      to={path}
                      sx={sessionStorage.site_id === "" ? display : ""}
                    >
                      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                      <ListItemText disableTypography primary={t(title)} />
                    </ListSubtyle>
                  );
                }
              }
              else {
                return (
                  <ListSubtyle
                    key={title}
                    component={RouterLink}
                    to={path}
                  >
                    <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                    <ListItemText disableTypography primary={t(title)} />
                  </ListSubtyle>
                );
              }
            })}
          </List>
        </Collapse>
      </>
    );
  }
  
  let is_active = true;
  
  if(item.company_required && (!sessionStorage.CurrentCompany || !sessionStorage.company_id)){
	  is_active = false;
  }
  
  if(item.site_required && (!sessionStorage.CurrentSite || !sessionStorage.site_id)){
	  is_active = false;
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={is_active ? path : "#"}
	  sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={t(title)} style={is_active ? { color: activeColor } : {}}  />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  
  
  let No = 0;
  const [drawNo, setDrawNo] = useState(No);
  
  const onCompanyOrSiteChange = () => {
	  No++;
	  setDrawNo(No);
	  
  };
	
  useEffect(() => {
    
	window.addEventListener("hpay-site-changed", onCompanyOrSiteChange, false);
	window.addEventListener("hpay-company-changed",onCompanyOrSiteChange, false);
	
	return () => {
		window.removeEventListener("hpay-site-changed", onCompanyOrSiteChange, false);
		window.removeEventListener("hpay-company-changed", onCompanyOrSiteChange, false);
	};
		
    // eslint-disable-next-line  
  }, [])	

  return (
    <Box {...other}>
      <List disablePadding className={"renderno-" + drawNo}>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
