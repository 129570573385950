import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Button, Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function RowButtons({ Items, Caption = null}) {
    
	const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
	//const [menuItems,setMenuItems]= useState(Items);
	
	const menuItems = Items

    return (
        <>
			
			{Caption ? <Button color="primary" variant="contained" endIcon={<KeyboardArrowDownIcon/>} ref={ref} onClick={() => setIsOpen(true)} >{Caption}</Button>
			: <IconButton ref={ref} onClick={() => setIsOpen(true)}>
			    <Icon icon={moreVerticalFill} width={20} height={20} />
            </IconButton>}
		
            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
			{
				menuItems.map((item, key) => {
					
					let icon = item.icon;
					if(icon == "delete"){
						icon = trash2Outline;
					}else if(icon == "edit"){
						icon = editFill;
					}else{
						icon = null;
					}	
					
					return (<MenuItem key={key} sx={{ color: 'text.secondary' }}>
							<ListItemIcon>
								<Icon icon={icon} width={24} height={24} />
							</ListItemIcon>
							<ListItemText primary={item.text} onClick={(e) => {setIsOpen(false); item.callback(); } } primaryTypographyProps={{ variant: 'body2' }} />
					   </MenuItem>);
					
				})
			}
			
                
            </Menu>
        </>
    );
}
