import Iframe from 'react-iframe'
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
//import AuthSocialLogin from '../components/authentication/AuthSocialLogin';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import LanguageBar from "../layouts/dashboard/LanguagePopover";



const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function Login() {
	
  const { t, i18n } = useTranslation();
  const load = async (params) => {
	if(!sessionStorage.language){
		sessionStorage.language = "rs";
	}
	  
	if(!localStorage.country_detected){
		await fetch('https://apps.holest.com/iploc.php?ip=auto', {}).then(r => r.json()).then(res => {
		  const country_name = res.CountryName;
		  localStorage.country_detected = country_name;
		  const language = res.CountryCode2;
		  if (country_name === "Serbia" || country_name === "BiH" || country_name === "Montenegro") {
			i18n.changeLanguage('rs');
		  }else {
			i18n.changeLanguage('en');
		  }
		  sessionStorage.language = i18n.language;
		  localStorage.hpay_languange = i18n.language;
		}).catch(err =>{
			  
			  
		  });	
	}else{
		const country_name = localStorage.country_detected;
		if (country_name === "Serbia" || country_name === "BiH" || country_name === "Montenegro") {
			i18n.changeLanguage('rs');
		}else {
			i18n.changeLanguage('en');
		}
		sessionStorage.language = i18n.language;
		localStorage.hpay_languange = i18n.language;
	}  
  };
  
  const loadInfoPanels = function(){
	  if(!sessionStorage.language){
		  sessionStorage.language = "rs";
	  }
	  
	  if(window["info_content_top_" + sessionStorage.language]){
		  if(document.getElementById('info_content_top'))
			document.getElementById('info_content_top').innerHTML = window["info_content_top_" + sessionStorage.language];
	  }else{
		  fetch("./static/landing_top_" + sessionStorage.language + ".html").then(r => {
			  return r.text()	  
		  }).then(html => {
			  if(document.getElementById('info_content_top')){
				window["info_content_top_" + sessionStorage.language] = document.getElementById('info_content_top').innerHTML = html;
			  }
		  }).catch(err =>{
			  
			  
		  });
	  }
	  
	  if(window["info_content_bottom_" + sessionStorage.language]){
		  if(document.getElementById('info_content_bottom'))
			document.getElementById('info_content_bottom').innerHTML = window["info_content_bottom_" + sessionStorage.language];
	  }else{
		  fetch("./static/landing_bottom_" + sessionStorage.language + ".html").then(r => {
			  return r.text()	  
		  }).then(html => {
			  if(document.getElementById('info_content_bottom')){
				window["info_content_bottom_" + sessionStorage.language] = document.getElementById('info_content_bottom').innerHTML = html;
			  }
		  }).catch(err =>{
			  
			  
		  });
	  }
  };
  
  loadInfoPanels();
  document.addEventListener("languange_changed", function(e){
	  loadInfoPanels();
  });
  

  useEffect(() => {
    load();
    // eslint-disable-next-line
  }, [])
  return (
    <RootStyle className="auth-content" title="Login | Holest">
      
	  <div className='auth-side-panel'>
		
		  <AuthLayout>
			<LanguageBar />
			{t("Don’t have an account?")} &nbsp;
			<Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
			  {t("Register")}
			</Link>
		  </AuthLayout>
		  
		
		  <MHidden width="mdDown">
			<SectionStyle>
			  <Typography className="register-promo-text" variant="h5" sx={{ px: 5, mt: 10, mb: 5 }}>
				{t("Seamless site payments | Shipping Automation | Fiscalization")}
			  </Typography>
			  <img src="/static/illustrations/illustration_login.png" alt="login" />
			</SectionStyle>
		  </MHidden>
	  </div>
	  
	  
      <Container className='auth-form' maxWidth="sm">
        <ContentStyle>
		{!sessionStorage.addsite ?
          <Stack sx={{ mb: 5 }}>
            <div id="info_content_top" />
		</Stack> : null}
		  {/*
          <AuthSocialLogin />
		  */}

          <LoginForm />
		  
		  
		  <br/>
		  {!sessionStorage.addsite ?
          <div id="info_content_bottom" />
			: null}
          
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
