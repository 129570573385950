import Page from '../components/Page';
import { Container, Button, Stack, Typography, Table, TableRow, TableHead, TableBody,TableFoot, TableCell, TableContainer, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import configData from "../config.json";
import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
import { useEffect } from 'react';
import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function CreatePayment() {
    const history = useHistory();
    const [paymentMethods, setPaymentMethods] = useState([]);
    
	//const [SitePayment, setSitePayment] = useState({});
    //const [Details, setDetails] = useState();
    //const [ValueObject, setValueObject] = useState({});
    //const [sitepaymentMethods, setSitepaymentMethods] = useState([]);
    
	const [AlertMessage, setAlertMessage] = useState("success");
    const [AlertType, setAlertType] = useState("success");
    const [AlertOpen, setAlertOpen] = useState(false);
    const { t } = useTranslation();
	
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
	
	
	

/*
    const handleChange = (event) => {
        const data = event.target.value;
        setDetails(data.Data.parameters);
        setSitePayment(data);
    };
*/
    const AlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    };

    const httpClient = (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
        }
        const token = JSON.parse(sessionStorage.AccessToken).Token;
        options.headers.set('Authorization', `${token}`);
        return fetchUtils.fetchJson(url, options);
    };

    const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
	
	const back = (params) => {
        history.push('/paymentMethods')
    }

    const handleAdd = (method_uid) => {
       
        
		let method = paymentMethods.find((m) => m.Name == method_uid);
		
		if(method.Data){
			
			let Uid = method.Name;
			
			let def_parameters = {};
			(method.Data.parameters || []).forEach(p => {
				def_parameters[p.Name] = p.Default || null;
			});
			
			dataProvider.create('company_site_payment_methods', { data: { PaymentMethodId: method.id, CompanySiteId: sessionStorage.site_id, Uid: Uid, Data: JSON.stringify({parameters: def_parameters}) } })
                .then(res => {
                    history.push('/paymentdetails/' + res.data.id);
                })
                .catch(error => {
					if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
						let event = new Event("hpay-auth-expired");
						window.dispatchEvent(event);
					}
					
                    setAlertMessage(t("Error during adding of this payment method!"));
					setAlertType("error");
					setAlertOpen(true);
                });
		}else{
			setAlertMessage(t("Error: could not add this payment method at the moment!"));
            setAlertType("error");
            setAlertOpen(true);
		}
		
    }

    const load = (params) => {
        //let payment_methods = [];
        dataProvider.getList("payment_methods", { pagination: { page: 1, perPage: 10 }, sort: { field: 'id', order: 'ASC' }, filter: {} })
            .then(res => {
                setPaymentMethods(res.data);
		    });
    }
    useEffect(() => {
        load();
		
		window.addEventListener("hpay-site-changed", back, false);
		return () => {
			window.removeEventListener("hpay-site-changed", back, false);
		};
        // eslint-disable-next-line
    }, [])
    return (
        <Page title="Company | Holest">
            <Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={AlertClose}>
                <Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
                    {AlertMessage}
                </Alert>
            </Snackbar>
            <Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
					<Stack direction="column"  mb={5}>
						<Typography variant="h4" gutterBottom>
							{t("Select payment method to add...")}
						</Typography>
						<Typography variant="p" gutterBottom>
							*** {JSON.parse(sessionStorage.CurrentSite || "{}").name} ***
						</Typography>
					</Stack>
					
					<Button
							variant="dark"
							onClick={(e) => back()}
							startIcon={<ExitToAppIcon />}
							color="secondary"
						>
							{t("Back to site payment methods...")}
					</Button>
				</Stack>	
            </Container>
			
			<Stack>
				<TableContainer sx={{  }}>
				  <Table>
					<TableHead>
						<TableRow>
							<TableCell>
								{t("Name")}
							</TableCell>
							<TableCell>
								{t("Payment Type")}
							</TableCell>
							<TableCell>
								{t("Info")}
							</TableCell>
							<TableCell>
								{t("Recurring support")}
							</TableCell>
							<TableCell>
								{t("Is instant?")}
							</TableCell>
							<TableCell>
								{t("HPay cardform")}
							</TableCell>
							<TableCell>
							
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					  {paymentMethods.map((row, key) => {
						  let isItemSelected = selectedPaymentMethod == row.Name;
						  return (
							<TableRow
							  hover
							  key={key}
							  tabIndex={-1}
							  role="checkbox"
							  selected={isItemSelected}
							  //aria-checked={isItemSelected}
							  className={'clickable'}
							  onClick={(e) => setSelectedPaymentMethod(row.Name)}
							>
							  <TableCell>
								{row.Title}
							  </TableCell>
							  <TableCell>
								{row.PaymentType}
							  </TableCell>
							  <TableCell>
								<div> 
								<h6>{t("Known banks/financial institutions")}:</h6>
								{row.KnownBanks ? t(row.KnownBanks) : "--"}
								</div>
								{isItemSelected ?
									<div> 
										<br/>
										<h6>{t("Description")}:</h6>
										{row.Description ? t(row.Description) : "--"}
									</div>
								: null}
								
								{isItemSelected ? 
									<div>
										<br/>
										<h6>{t("Requirements")}:</h6>
										{row.Requirements ? t(row.Requirements) : "--"}
									</div>
								: null}
							 	
							  </TableCell>
							  <TableCell>
								{row.SubsciptionsType} {row.SubsciptionsType == "cof-tokenization" ? <span style={{color:"green"}}> ✓</span> : "" }
							  </TableCell>
							  <TableCell>
								{row.Instant ? t("Yes") : t("No")}
							  </TableCell>
							  <TableCell>
								{row.HPCardPay ? t("Yes") : t("No")}
								{row.Fallback ?
									<span style={{color:"green"}}> fallback✓</span> : null}
								{row.HPCardPay ? <span style={{color:"green"}}> routing✓</span> :null}
								{row.Splitpay ?
									<span style={{color:"green"}}> splitpay✓</span> : null}
							  </TableCell>
							  <TableCell>
							  {isItemSelected ?
								<Button
									size="medium"
									type="submit"
									color="secondary"
									endIcon={<SendIcon />}
									variant="contained"
									onClick={(e) => handleAdd(row.Name) }
								>
									{t("ADD")}
								</Button>
							  : null
							  }
							  </TableCell>
							</TableRow>
						  );
						})}
					  
					</TableBody>
				  </Table>
				</TableContainer>
			</Stack>
	    </Page >
    );
}
