import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next, useTranslation } from 'react-i18next';
import HPAY_TRANSLATION from "./i18n_translation";

i18n.use(LanguageDetector).use(initReactI18next).init({
    // we init with resources
	fallbackLng: "en",
    debug: true,
	keySeparator: false, // we use content as keys
	supportedLngs: HPAY_TRANSLATION.languages,
    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },
	
    react: {
        wait: true
    },
	resources: HPAY_TRANSLATION
});

let HPAY_LNGS = HPAY_TRANSLATION.languages;
const x = HPAY_TRANSLATION;
i18n.HPAY_LNGS = HPAY_LNGS;

export default i18n;
