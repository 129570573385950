import * as Yup from 'yup';
import { useState, forwardRef, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FingerprintAppIcon from '@mui/icons-material/Fingerprint';
import { io } from 'socket.io-client';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useCookies } from 'react-cookie';
import SendIcon from '@mui/icons-material/Send';
import common from "../../../common.js";


// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Button,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import configData from "../../../config.json";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function LoginForm() {
  const [cookies, setCookie, removeCookie] = useCookies(['hpayremember','hpayrememberexp']);
  const {hpbtoa, hpatob} = common;
  
  const { t } = useTranslation();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("success");
  const [AlertType, setAlertType] = useState("success");
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [verifyPIN, setVerifyPIN] = useState(false);
  const [PIN, setPIN] = useState(["","","","","",""]);
  
  const [forgetDialogOpen, setForgetDialogOpen] = useState(false);
  
  const [forgetEmail, setForgetEmail]         = useState("");
  const [forgetPassword, setForgetPassword]   = useState("");
  const [forgetRPassword, setForgetRPassword] = useState("");
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  
  const [externalVerifyUrl, setExternalVerifyUrl] = useState("");
  const [externalQRDataUrl, setExternalQRDataUrl] = useState("");
  const [externalDeviceConnected, setExternalDeviceConnected] = useState(false);
  
  const [challangeWebAuthN, setChallangeWebAuthN] = useState(false);
  
  const [passwordInputValid, setPasswordInputValid] = useState({valid:null, message:"", rmessage:""});
  
  const closeForgetDialog = () => {
	setForgetDialogOpen(false);  
  };
  
  const openForgetDialog = () => {
	setForgetDialogOpen(true);
	
	if(document.querySelector("input[name='email']")){
		setForgetEmail( document.querySelector("input[name='email']").value );	
	}  
	  
  };
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('Email must be a valid email address')).required(t('Email is required')),
    password: Yup.string().required(t('Password is required'))
  });
  
  const LABELS = {
	INPUT_INSUFFICIENT: t("Please enter correct email and password"),
    BAD_EMAIL_OR_PASSWORD: t("No such user or bad password"),
	SERVICE_UNAVALABLE: t("Service is currently unavailable. Please try later..."),
	NOT_FOUND_OR_EXPIRED: t("Handshake session expired or not found"), 
	DATA_MISMATCH: t("Data mismatch! Please retry registration from beginning!"),
	PASSWORD_NOT_ACCEPTABLE	: t("Password must meet complexity requirements"),
	PASSWORDS_MISMATCH: t("Password confirmation mismatch"),
	TEMPORARILY_BLOCKED:t("You are temporarily blocked becuse of lot of request is a short time period. Try in a few minutes!")	
  };
  
  const base64encode = (arrayBuffer) => {
		if (!arrayBuffer || arrayBuffer.length == 0)
			return undefined;
		return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)));
  };

  const arrayBufferToString = (arrayBuffer) => {
		return String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
  };

  const stringToArrayBuffer = (str) => {
		return Uint8Array.from(str, c => c.charCodeAt(0)).buffer;
  };
  
  const validateEmail = (email) => {
	  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email).toLowerCase());
  };
  
  const validatePasswordInput = (pass, rpass) => {
	  
	  if(pass){
		  passwordInputValid.rmessage = "";
		  
		  if(pass.length < 8){
			  passwordInputValid.valid = false;
			  passwordInputValid.message = t("Please enter at least 8 characters");
			  
		  }else if(!/\d/.test(pass)){
			  passwordInputValid.valid = false;
			  passwordInputValid.message = t("Please enter at least 1 number");
			  
		  }else if(!/[a-z]/.test(pass)){
			  passwordInputValid.valid = false;
			  passwordInputValid.message = t("Please enter at least 1 lowercase letter");
			  
		  }else if(!/[A-Z]/.test(pass)){
			  passwordInputValid.valid = false;
			  passwordInputValid.message = t("Please enter at least 1 uppercase letter");
			  
		  }else if(!/[\`|\~|\!|\@|\$|\%|\^|\&|\*|\(|\)|\+|\-|\=|\{|\}|\[|\]|\:|\;|\"|\'|\||\\|\/|\<|\>|\,|\.|\?]/.test(pass)){
			  passwordInputValid.valid = false;
			  passwordInputValid.message = t("Please enter at least 1 special character");
			  
		  }else if(pass !== rpass){
			  passwordInputValid.valid = false;
			  passwordInputValid.message = "";
			  passwordInputValid.rmessage = t("Passwords do not match");
		  }else{
			  passwordInputValid.valid = true;
			  passwordInputValid.message = "";
			  passwordInputValid.rmessage = "";
		  }
	  }else{
		  passwordInputValid.valid = null;
		  passwordInputValid.message = "";
		  passwordInputValid.rmessage = "";
	  }
	  
	  setPasswordInputValid(passwordInputValid);
	  
	  return passwordInputValid.valid;
  };
  
  
  const verifybyemail = (Email, Reference) => {
	  setChallangeWebAuthN(false);
	  setIsSubmitting(true);
	  
	  fetch(configData.API_URL + 'verifybyemailsecuritycode', {
		  method:"POST",
		  headers: {
			"Content-Type": "application/json",
		  },
		  body:JSON.stringify({
			Email, 
			Reference,
			language: sessionStorage.language
		  })
	  }).then(raw_response => {
		  return raw_response.json();
	  }).then(response => {
		  setIsSubmitting(false);
		  
		  if(response.error){
			
			setAlertMessage(LABELS[response.error] ? LABELS[response.error] : response.error);
			setAlertType("error");
			setOpen(true);
			  
		  }else{
			  setPIN(["","","","","",""]);
			  setVerifyPIN(true);
			  setAlertMessage(t("Identity verification") + " (" +  (response.email_verify ? response.email_verify : "--")  + "). " +  t("Please check you email for security code we sent you."));
			  setAlertType("success");
			  setOpen(true);
			  setVerifyPIN(true);
				
			  try{
					document.getElementById("PIN1").focus();
			  }catch(pex){
				  
			  }
		  }
		  
	  }).catch(err => {
		  setIsSubmitting(false);
		  setAlertMessage(t("Error! Please try again or later..."));
		  setAlertType("error");
		  setOpen(true);
	  });	  
  };
  
  const loginAccountRequest = () => {
	  
	  setIsSubmitting(true);
	  
	  window.loginData.language = sessionStorage.language;	  
	  
	  window.__verify_by_mail_swap = false;
		
      fetch(configData.API_URL + 'login', {
		  method:"POST",
		  headers: {
			"Content-Type": "application/json",
		  },
		  body:JSON.stringify(window.loginData)
	  }).then(raw_response => {
		  
		  return raw_response.json();
		  
	  }).then(response => {
		  
		  
		  
          if(response.error){
			setIsSubmitting(false);
			setAlertMessage(LABELS[response.error] ? LABELS[response.error] : response.error);
            setAlertType("error");
            setOpen(true);
		  }else{
			  
			if(response.verify_device_ref){
				setForgetDialogOpen(false);
				window.loginData.Reference = response.verify_device_ref;
				if(response.challenge && response.IDS && response.IDS.length){
					
					setExternalDeviceConnected(false);
					let url = window.location.origin + "/authn/a/" + window.loginData.Reference + "/" + sessionStorage.language;
					setExternalVerifyUrl(url);
					
					window.QRCode.toDataURL(url, {width:128}).then(d => {
						setExternalQRDataUrl(d);
					});
									
					window.getAssertionOptions = {
						allowCredentials: response.IDS.reverse().map(credentialId => { return {
																type: "public-key",
																id: Uint8Array.from(atob(credentialId), c=>c.charCodeAt(0)).buffer
															 }}),
						challenge: stringToArrayBuffer(response.challenge),
						timeout: 180000
					};
					
					if(window.verfy_socket){
						if(window.verfy_socket.Reference != window.loginData.Reference){
							window.verfy_socket.disconnect();
							window.verfy_socket = null;
						}
					}
					
					if(!window.verfy_socket){
						
						window.verfy_socket = io(configData.API_URL.split("/api")[0] ,{path: configData.IO_ENDPOINT, query:"refverify=" + window.loginData.Reference});
						window.verfy_socket.Reference = window.loginData.Reference;
						
						window.verfy_socket.on('connect_error', err => {
							if(isSubmitting){
								setIsSubmitting(false);
							}
							
							if(!window.__verify_by_mail_swap){
								window.__verify_by_mail_swap = true;
								verifybyemail(window.loginData.Email, window.loginData.Reference);
							}
							
						});
						
						window.verfy_socket.on('connect_failed', err => {
							if(isSubmitting){
								setIsSubmitting(false);
							}
							
							if(!window.__verify_by_mail_swap){
								window.__verify_by_mail_swap = true;
								verifybyemail(window.loginData.Email, window.loginData.Reference);
							}
						});
						
						window.verfy_socket.on("connect", function(){
							setIsSubmitting(false);
							setChallangeWebAuthN(true);
						});
						
						window.verfy_socket.on("disconnect", function(){
							 //
						});
						
						window.verfy_socket.on("onExternalRefConnected", function(data){
							try{
								if(data.ref == window.loginData.Reference){
									setExternalDeviceConnected(true);
								}
							}catch(ex){
								//
							}
						});
						
						window.verfy_socket.on("onExternalRefDisconnect", function(data){
							try{
								if(data.ref == window.loginData.Reference){
									setExternalDeviceConnected(false);
								}
							}catch(ex){
								//
							}
						});
						
						window.verfy_socket.on("onAuthorization", function(data){
							try{
								if(data.ref == window.loginData.Reference){
									const user_data = data.authorization.user;
									const authToken = data.authorization.authToken;
									sessionStorage.UserData = JSON.stringify(user_data);
									sessionStorage.AccessToken = JSON.stringify(authToken);
									if(data.persistant_verification){
										localStorage["hpayverifiedbrowser"] = data.persistant_verification;
									}
									
									let expires = (new Date());
									if(window.loginData.Remember){
										expires.setTime(expires.getTime() + (604800000));//7 days
									}else{
										expires.setTime(expires.getTime() + (2700000));//45 min
									}
									
									try{
										setCookie('hpayrememberexp', expires.getTime(), { path: '/',  expires});
										setCookie('hpayremember', hpbtoa(JSON.stringify(data.authorization)), { path: '/',  expires});
									}catch(ex){
										console.error(ex);
									}
									
									if(sessionStorage.addsite){
										history.push('/sites');
									}else{
										if(sessionStorage.afterloginurl){
											history.push(sessionStorage.afterloginurl);
											delete sessionStorage.afterloginurl;
										}else{
											history.push('/app');	
										}
									}
									
									if(window.verfy_socket){
										window.verfy_socket.disconnect();
										delete window.verfy_socket;
									}
									
								}
							}catch(ex){
								//
							}
						});
						
					}else if (!window.verfy_socket.connected){
						window.verfy_socket.connect();
					}else{
						setChallangeWebAuthN(true);
					}
					
				}else{
					setIsSubmitting(false);
					verifybyemail(window.loginData.Email, window.loginData.Reference);
				}
			}else if(response.authorization){
				const user_data = response.authorization.user;
				const authToken = response.authorization.authToken;
				sessionStorage.UserData = JSON.stringify(user_data);
				sessionStorage.AccessToken = JSON.stringify(authToken);
				if(response.persistant_verification){
					localStorage["hpayverifiedbrowser"] = response.persistant_verification;
				}
				
				let expires = (new Date());
				if(window.loginData.Remember){
					expires.setTime(expires.getTime() + (604800000));//7 days
				}else{
					expires.setTime(expires.getTime() + (2700000));//45 min
				}
				
				try{
					setCookie('hpayrememberexp', expires.getTime(), { path: '/',  expires});
					setCookie('hpayremember', hpbtoa(JSON.stringify(response.authorization)), { path: '/',  expires});
				}catch(ex){
					console.error(ex);
				}
				
				if(sessionStorage.addsite){
					history.push('/sites');
				}else{
					if(sessionStorage.afterloginurl){
						history.push(sessionStorage.afterloginurl);
						delete sessionStorage.afterloginurl;
					}else{
						history.push('/app');	
					}
				}
				
				if(window.verfy_socket){
					window.verfy_socket.disconnect();
					delete window.verfy_socket;
				}
			}   
	      }
		}).catch(error => {
			setIsSubmitting(false);
			setAlertMessage(t("Error! Please try again or later..."));
			setAlertType("error");
			setOpen(true);
        });
	  
  };
  
  const handleWebAuthNCurrentBrowser = () => {
	  
	  if(!window.verfy_socket.connected){
		  setAlertMessage(t("Could not perform Biometric/WebAuthN verification!") + t("Reverting to verification over e-mailed security code..."));
		  setAlertType("error");
		  setOpen(true);
		  verifybyemail(window.loginData.Email, window.loginData.Reference);
		  return;
	  }
	  
	  return navigator.credentials.get({
						publicKey: window.getAssertionOptions
					}).then(rawAssertion => {
						
						let assertion = {
							id: base64encode(rawAssertion.rawId),
							clientDataJSON: arrayBufferToString(rawAssertion.response.clientDataJSON),
							userHandle: base64encode(rawAssertion.response.userHandle),
							signature: base64encode(rawAssertion.response.signature),
							authenticatorData: base64encode(rawAssertion.response.authenticatorData)
						};
						
						window.verfy_socket.emit("webauthAssert", {verificationuid: window.loginData.Reference , assertion: assertion}, function(assert_resp){
							if(assert_resp.error){
								 setAlertMessage(t("Biometric/WebAuthN verification failed. You can try other verfication method..."));
								 setAlertType("error");
								 setOpen(true);
							}
						});
						
						
		  }).catch(err => {
			  setAlertMessage(t("Could not obtain credentials!") + t("Reverting to verification over e-mailed security code..."));
			  setAlertType("error");
			  setOpen(true);
			  
			  verifybyemail(window.loginData.Email, window.loginData.Reference);
			  
		  });
	  
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: !!cookies["hpayremember"]
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
		
	  if(forgetDialogOpen){
		  values.password = forgetPassword; 
		  values.email    = forgetEmail;
	  }	
	  
	  let data = {
			Email: values.email,
			Password: values.password,
			Remember: values.remember,
			Browserverify: localStorage.hpayverifiedbrowser || "",
			language: sessionStorage.language || ""
		  };
		  
	  if(forgetDialogOpen){
		  data.Email           = forgetEmail;
		  data.RPasswordReset  = forgetRPassword;
		  data.IsPasswordReset = 1;
	  }	  
		  
	  window.loginData = data;	  
	    
	  loginAccountRequest();
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;
  
  const lastDigit = (val) => {
	  val = String(val).replace(/[^\d]/g,"");
	  if(val == "")
		  return "";
	  if(String(val).length > 1){
		  return String(val).split("").slice(-1)[0];
	  } 
	  return String(val);
  };
  
  const setPinChar = (pos, chr) => {
	  let pinCopy = PIN.map(c=>c);
	  pinCopy[pos] = chr;
	  setPIN(pinCopy);
	  if(chr !== ""){
		  if(document.getElementById("PIN" + (pos + 2))){
			  document.getElementById("PIN" + (pos + 2)).focus();
		  }
	  }
	  
	  if(window.pinsubmithandle){
		  clearTimeout(window.pinsubmithandle);
		  window.pinsubmithandle = null;
	  }
	  window.pinsubmithandle = setTimeout(checkPINsubmit,400);
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  
  const checkPINsubmit = () => {
	  
	  let pin_input = ["","","","","",""];
	  for(var i = 1; i <= 6; i++){
		  pin_input[i - 1] = document.getElementById("PIN" + i).value;
	  }
	  
	  if(!pin_input.filter(c => c === "").length){
		  window.loginData.SecurityCode = pin_input.join("");
		  loginAccountRequest();
	  }
  };
  
  useEffect(() => {
        if(cookies["hpayremember"]){
			try{
				
				let authorization = JSON.parse(hpatob(cookies["hpayremember"]));
				fetch(configData.API_URL + "me", {
				  method: 'GET',
				  headers:{
					  Authorization: authorization.authToken.Token
				  }
				}).then(rawresp => {
					return rawresp.json();
				}).then(resp => {
					try{
						if(resp.id && resp.Email){
							sessionStorage.AccessToken = JSON.stringify(authorization.authToken);
							sessionStorage.UserData    = JSON.stringify(authorization.user);
							if(sessionStorage.addsite){
								history.push('/sites');
							}else{
								if(sessionStorage.afterloginurl){
									history.push(sessionStorage.afterloginurl);
									delete sessionStorage.afterloginurl;
								}else{
									history.push('/app');	
								}
							}
						}
					}catch(ex){
						//
					}
				}).catch(err => {
					//
				});
				
				
			}catch(ex){
				//
			}	
		}
		
		return () => {
			
		};
    }, [])
  
  return (
    <FormikProvider value={formik}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={AlertType} sx={{ width: '100%' }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
	  
	  <Dialog open={forgetDialogOpen} fullWidth={true} maxWidth="sm">
		<DialogTitle>{t("Access recovery")}</DialogTitle>
		<DialogContent>
		  <h3>{t("Password reset")}</h3>
		  
		  <p style={{fontSize:"90%"}}>{t("Enter your email, set a new password and confirm it. Then proceed to the identity verification.")}</p>
		  
		  <br/>
		  <TextField
            fullWidth
            autoComplete="username"
            type="email"
            style = {{back : 'white'}}
            label={t("Email address")}
            value={forgetEmail}
			error={!!forgetEmail && !validateEmail(forgetEmail)}
			helperText={forgetEmail ? (!validateEmail(forgetEmail) ? t("enter valid email"):"") : ""}
			onChange={(e) => {setForgetEmail(e.target.value);  }}
          />
		  
		  <br/>
			  
		  <TextField
			autoFocus
			margin="dense"
			label={t("New password")}
			type={showForgetPassword ? 'text' : 'password'}
			fullWidth
			variant="standard"
			InputProps={{
			  endAdornment: (
				<InputAdornment position="end">
				  <IconButton tabIndex={-1} onClick={(e) => { setShowForgetPassword( !showForgetPassword ) }} edge="end">
					<Icon icon={showForgetPassword ? eyeFill : eyeOffFill} />
				  </IconButton>
				</InputAdornment>
			  )
			}}
			error={passwordInputValid.valid === false}
			helperText={passwordInputValid.message}
			value={forgetPassword}
			onChange={(e) => {setForgetPassword(e.target.value); validatePasswordInput(e.target.value, forgetRPassword);}}
		  />
		  
		  <TextField
			margin="dense"
			label={t("Repeat new password")}
			type={showForgetPassword ? 'text' : 'password'}
			fullWidth
			variant="standard"
			InputProps={{
			  endAdornment: (
				<InputAdornment position="end">
				  <IconButton tabIndex={-1} onClick={(e) => { setShowForgetPassword( !showForgetPassword ) }} edge="end">
					<Icon icon={showForgetPassword ? eyeFill : eyeOffFill} />
				  </IconButton>
				</InputAdornment>
			  )
			}}
			error={passwordInputValid.valid === false}
			helperText={passwordInputValid.rmessage}
			value={forgetRPassword}
			onChange={(e) => {setForgetRPassword(e.target.value); validatePasswordInput(forgetPassword, e.target.value);}}
		  />
		  
		  
		   <br/>
		    <br/>
		</DialogContent>
		<DialogActions>
		  <Button disabled={passwordInputValid.valid !== true || !validateEmail(forgetEmail)} size='small' onClick={(e) => { 
		  
				setFieldValue("email", forgetEmail);
				setFieldValue("password", forgetPassword);				
		  
				
				//document.querySelector("input[name='email']").value = forgetEmail;
				//document.querySelector("input[name='password']").value = forgetPassword;
				
				
				document.querySelector("#cmdLogin").click();
		  }} variant="contained" >{t("Proceed to identity verification...")}</Button>
		  <Button onClick={(e) => {closeForgetDialog()} }>{t("Close")}</Button>
		</DialogActions>
	  </Dialog>
	  
	  {challangeWebAuthN ? 
		<Stack spacing={3} marginTop={3}>
			<Typography variant='h5' style={{fontWeight:"bold"}}>
                {t("Identity verification required")}:
            </Typography>
			<Typography variant='h7' style={{fontWeight:"bold"}}>
                {t("Biometric/WebAuthN verification device selection")}:
            </Typography>
			  
			<Stack direction="row" justifyContent="space-around"  style={{display:"flex"}} alignItems="center"> 
			  
				  <Button style={{maxWidth:"40%"}}
						onClick={(e) => handleWebAuthNCurrentBrowser()}
						startIcon={<FingerprintAppIcon />}
						type="submit"
						color="secondary"
						variant="contained"
					>
						{t("Use current browser")}
				  </Button>
				  
				  <span>{t("OR")}</span>
				  
				  <Stack direction="column"  style={{maxWidth:"40%"}}>
					<h6>{t("Scan this QR code with your mobile phone if you are using your phone as external verification device")}</h6>
					<div className="webauthn-qr-area" style={{ 
						display:"inline-block", 
						width:"128px", 
						height:"128px"
						}}>
						
						{!externalDeviceConnected ?
						<img style={{width:"128px", height:"128px"}} src={externalQRDataUrl} /> :
						<div className="lds-ripple"><div></div><div></div></div> }
						
					</div>
					<Typography variant="p" style={{fontSize:"65%"}} gutterBottom>
					  {t("Equivalent url")}:<br/>{externalVerifyUrl}
					</Typography>
				  </Stack>
				  
			</Stack>
			
			<br/>
			<Button
					variant="dark"
					onClick={(e) => {
						verifybyemail(window.loginData.Email, window.loginData.Reference);
					}}
					startIcon={<ExitToAppIcon />}
					color="secondary"
				>
					{t("Try different identity verification method...")}
			</Button>
			<br/>
			
		</Stack> :
	  verifyPIN ? 
		<Stack spacing={3} marginTop={3}>
			<Typography variant='h5' style={{fontWeight:"bold"}}>
                {t("Identity verification required")}:
            </Typography>
			<Typography variant='h7' style={{fontWeight:"bold"}}>
			{t("E-mail address verification pending...")}
		    </Typography>
			
			<Typography variant='p' style={{color:"gray", fontSize:"80%", textAlign:"justify"}}>
			{t("Please check your mailbox for 6-digit security code we sent you. Don't forget to check SPAM/Trash/Junk folders also if you don't find a mail message containing security code in your primary inbox.")}
		    </Typography>
			
			<Typography variant='h6' >
			{t("SECURITY CODE")}
		    </Typography>
			
			<Stack direction={{ xs: 'row'}} spacing={3}>
			
			{[1,2,3,4,5,6].map((keyInd,ind) => {
				
				return <TextField
				  label=""
				  id={"PIN" + keyInd}
				  key={"PIN" + keyInd}
				  disabled={isSubmitting}
				  type="number"
				  value={PIN[ind]}
				  style={{textAlign:"center", fontSize:"20px"}}
				  inputProps={{ style:{textAlign:"center", fontSize:"1.7em", padding:"12px 6px"} ,pattern:"\d"}}
				  onInput={(e) => {
					  setPinChar(ind,lastDigit(e.target.value));
				  }}
				  onKeyDown={(e) => {
					  let key = e.key;
					  if(/ArrowLeft|Backspace/.test(key)){
						  if(document.getElementById("PIN" + (keyInd - 1))){
							  document.getElementById("PIN" + (keyInd - 1)).focus();
						  }
					  }else if(/ArrowRight/.test(key)){
						  if(document.getElementById("PIN" + (keyInd + 1))){
							  document.getElementById("PIN" + (keyInd + 1)).focus();
						  }
					  }
				  }}
				  onPaste={(e) => {
					  e.stopPropagation();
					  e.preventDefault();

					  let clipboardData = e.clipboardData || window.clipboardData;
					  let pastedData = String(clipboardData.getData('Text')).trim().replace(/[^\d]/g,"");

					  if(String(pastedData).length == PIN.length){
						  
						  String(pastedData).split("").forEach((c,index) =>{
							  document.getElementById("PIN" + (index + 1)).value = c;
						  });
						  
						  setPIN(String(pastedData).split(""));
						  
						  window.loginData.SecurityCode = pastedData;
						  
						  if(window.pinsubmithandle){
							  clearTimeout(window.pinsubmithandle);
							  window.pinsubmithandle = null;
						  }
						  window.pinsubmithandle = setTimeout(checkPINsubmit,400);
					  }
					  
				  }}
				/>
			})}
			
			
			
			
					
			</Stack>
			<br/>
			<Button
					variant="dark"
					onClick={(e) => setVerifyPIN(false)}
					startIcon={<ExitToAppIcon />}
					color="secondary"
				>
					{t("Cancel and return to the registration form...")}
			</Button>
			<br/>
			<br/>
		</Stack> : 
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            style = {{back : 'white'}}
            label={t("Email address")}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={t("Password")}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton tabIndex={-1} onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label={t("Remember me on this browser")}
          />

          <Link component={RouterLink} variant="subtitle2" to="#" onClick={(e) => { openForgetDialog() }}>
            {t("Forgot password?")}
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
		  id="cmdLogin"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {t("Login")}
        </LoadingButton>
      </Form>}
    </FormikProvider>
  );
}
