import 'simplebar/src/simplebar.css';
import i18n from "./i18n";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
//import * as serviceWorker from './serviceWorker';
//import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import { deviceType, osName, browserName, isMobile } from 'react-device-detect';
import countries from "./countries.json";

const root_el = document.getElementById("root");
root_el.className = ((root_el.className || "") + " " + deviceType + " " + osName.toLowerCase() + " " + browserName.toLowerCase() + " " + (isMobile ? "mobile" : "pc")).trim();
const root = ReactDOM.createRoot(root_el);
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
		<App />
	  </I18nextProvider>
    </BrowserRouter>
  </HelmetProvider>
  	
);

// If you want to enable client cache, register instead.
//serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();