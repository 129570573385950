import jsonServerProvider from 'ra-data-json-server';

export default function hpayApiDataProvider(...provider_args) {
	let dprovider = jsonServerProvider(...provider_args);
	
	dprovider.__update = dprovider.update;
	dprovider.__delete = dprovider.delete;
	dprovider.__create = dprovider.create;
	
	dprovider.update = async function(...args){
		return new Promise(function(resolve, reject){
			dprovider.__update(...args).then(r => {
				resolve(r);
				
				let event = new Event("hpay-record-updated");
				event.Data = {
					response: r,
					endpoint: args[0],
					request: args[1]
				}; 
				window.dispatchEvent(event);
						  
			}).catch(err => {
				reject(err)
			});
		});
	};
	
	dprovider.delete = async function(...args){
		return new Promise(function(resolve, reject){
			dprovider.__delete(...args).then(r => {
				resolve(r);
				
				let event = new Event("hpay-record-deleted");
				event.Data = {
					response: r,
					endpoint: args[0],
					request: args[1] 
				}; 
				window.dispatchEvent(event);
				
			}).catch(err => {
				reject(err)
			});
		});
	};
	
	dprovider.create = async function(...args){
		return new Promise(function(resolve, reject){
			dprovider.__create(...args).then(r => {
				resolve(r);
				
				let event = new Event("hpay-record-created");
				event.Data = {
					response: r,
					endpoint: args[0],
					request: args[1]
				}; 
				window.dispatchEvent(event);
				
			}).catch(err => {
				reject(err)
			});
		});
	};
	
	return dprovider;
}