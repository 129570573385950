import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import { useTranslation } from 'react-i18next';
import { srRS } from '@mui/material/locale';

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node
};



export default function ThemeConfig({ children }) {
  const { t } = useTranslation();
  
  const themeOptions = useMemo(
    () => ({
      palette,
      shape,
      typography,
      shadows,
      customShadows
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);
  
  const object_assign = (target, ...sources) => {
		  if(sources && sources.length){
			for(var i = 0; i < sources.length;i++){
				target = object_assign_single_source(target, sources[i]);
			}
		  }
		  return target || {};
		};
  
  
  const object_assign_single_source = (target, source) => {
		if(source){
			if(!target)
				target = Array.isArray(source) ? [] : {};
			Object.keys(source).forEach(key => {
			  let s_val = source[key];
			  if(s_val){
				 let t_val = target[key];
				 if(t_val && (typeof s_val === typeof t_val)){
					 if(typeof s_val === "object"){
						target[key] = object_assign(t_val,s_val);
					 }else{
						target[key] = s_val;
					 }
				 }else{
					 if(typeof s_val === "object"){
						target[key] = object_assign(Array.isArray(s_val) ? [] : {},s_val);
					 }else{
						target[key] = s_val;
					 }
				 }
			  }else
				 target[key] = s_val;
			});
		}
		return target;
	  };
  
  theme.components = object_assign_single_source(theme.components,{
		MuiBreadcrumbs: { defaultProps: {
		  expandText: t('Show path'),
		}},
		MuiTablePagination: { defaultProps: {
		  getItemAriaLabel: (type) => {
			if (type === 'first') {
			  return t('Go to first page');
			}
			if (type === 'last') {
			  return t('Go to last page');
			}
			if (type === 'next') {
			  return t('Go to next page');
			}
			// if (type === 'previous') {
			return t('Go to previous page');
		  },
		  labelRowsPerPage: t('Rows per page:'),
		  labelDisplayedRows: ({ from, to, count }) =>
	  `${from}–${to} ${t('of')} ${count !== -1 ? count : `more than ${to}`}`,
		}},
		MuiRating: { defaultProps: {
		  getLabelText: value => `${value} Star${value !== 1 ? 's' : ''}`,
		  emptyLabelText: t('Empty'),
		}},
		MuiAutocomplete: { defaultProps: {
		  clearText: t('Clear'),
		  closeText: t('Close'),
		  loadingText: t('Loading…'),
		  noOptionsText: t('No options'),
		  openText: t('Open'),
		}},
		MuiAlert: { defaultProps: {
		  closeText: t('Close'),
		}},
		MuiPagination: {  defaultProps: {
		  'aria-label': t('Pagination navigation'),
		  getItemAriaLabel: (type, page, selected) => {
			if (type === 'page') {
			  	
			  return `${selected ? '' : (t('Go to') + ' ')}${t('page')} ${page}`;
			}
			if (type === 'first') {
			  return t('Go to first page');
			}
			if (type === 'last') {
			  return t('Go to last page');
			}
			if (type === 'next') {
			  return t('Go to next page');
			}
			// if (type === 'previous') {
			return t('Go to previous page');
		  },
		}},
	  });
  

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
