import { filter } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';
import { Card, Table, Stack, Button, TableRow, TableBody, TableHead, TableCell, Container,TextField, Typography, TableContainer, TablePagination } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
import configData from "../config.json";
import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
import { useHistory } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
//import SelectSite from './SelectSite'
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useParams } from 'react-router';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import RowButtons from './rowbuttons';
import RefreshIcon from '@mui/icons-material/Refresh';


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Orders() {
  const params = useParams();
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  
  const compilableMethods = ["orderActions","orderListActions","subscriptionActions","subscriptionListActions","transactionActions","transactionListActions","getInfo","initActions"]; 
  
  const [selected, setSelected] = useState([]);
  //const [filterName, setFilterName] = useState('');
 
  const [Items, setItems] = useState([]);
  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [AlertMessage, setAlertMessage] = useState("success");
  const [AlertType, setAlertType] = useState("success");
  const [AlertOpen, setAlertOpen] = useState(false);
  const { t } = useTranslation();
  
  const [paymentMethods,  setPaymentMethods]          = useState( (window.__sitesData && window.__sitesData[sessionStorage.site_id] && window.__sitesData[sessionStorage.site_id].done) ? window.__sitesData[sessionStorage.site_id].payment_methods : {});
  const [shippingMethods, setShippingMethods]         = useState( (window.__sitesData && window.__sitesData[sessionStorage.site_id] && window.__sitesData[sessionStorage.site_id].done) ? window.__sitesData[sessionStorage.site_id].shipping_methods : {});
  const [fiscalMethods,   setFiscalMethods]           = useState( (window.__sitesData && window.__sitesData[sessionStorage.site_id] && window.__sitesData[sessionStorage.site_id].done) ? window.__sitesData[sessionStorage.site_id].fiscal_methods : {});
  const [sitePaymentMethods,  setSitePaymentMethods]  = useState( (window.__sitesData && window.__sitesData[sessionStorage.site_id] && window.__sitesData[sessionStorage.site_id].done) ? window.__sitesData[sessionStorage.site_id].site_payment_methods : {});
  const [siteShippingMethods, setSiteShippingMethods] = useState( (window.__sitesData && window.__sitesData[sessionStorage.site_id] && window.__sitesData[sessionStorage.site_id].done) ? window.__sitesData[sessionStorage.site_id].site_shipping_methods : {});
  const [siteFiscalMethods,   setSiteFiscalMethods]   = useState( (window.__sitesData && window.__sitesData[sessionStorage.site_id] && window.__sitesData[sessionStorage.site_id].done) ? window.__sitesData[sessionStorage.site_id].site_fiscal_methods : {});
  
  const [fromDate, setFromDate] = useState(new Date((new Date()).getTime() - 30 * 24 * 60 * 60 * 1000));
  const [toDate, setToDate] = useState(new Date());
  const [search, setSearch] = useState("");
  
  const [isLoading, setIsLoading] = useState(true);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  
  const [detailsDialogTitle, setDetailsDialogTitle] = useState("");
  const [detailsDialogContent, setDetailsDialogContent] = useState("");
  const [detailsDialogActions, setDetailsDialogActions] = useState([]);
  const [detailsDialogSize, setDetailsDialogSize] = useState("md");
  
  
  const [emptyMessage, setEmptyMessage] = useState(t("Loading..."));
  
  const [refreshNo, setRefreshNo] = useState(1);
  
  const AlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  
  
  const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
    }
    const token = JSON.parse(sessionStorage.AccessToken).Token;
    options.headers.set('Authorization', `${token}`);
    return fetchUtils.fetchJson(url, options);
  };
  const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = Items.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    loadData(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    loadData(1, parseInt(event.target.value));
  };
  
  const prepareObjectForUse = (obj, full) => {
	
	if(obj && obj.toJSON){
		obj = obj.toJSON();
	}
	
	Object.keys(obj).filter(k => /^Data$|[a-z]Data$/.test(k) && obj[k]).forEach(k=> {
		obj[k] = object_if_json_string(obj[k]);
	});
	
	if(full){
		Object.keys(obj).filter(k => /^Subscription$|^Order$/.test(k) && obj[k]).forEach(k=> {
			obj[k] = prepareObjectForUse(obj[k], full);
		});
		
		Object.keys(obj).filter(k => /^Orders$|^Transactions$/.test(k) && obj[k]).forEach(k=> {
			if(obj[k] && obj[k].length){
				obj[k] = obj[k].map(item => prepareObjectForUse(item, full));
			}
		});
	}
	
	return obj;
  };

  const itemEdit = (item) => {
    openDetailsDialog(t("Details") + ": " + item.Uid, JSON.stringify(prepareObjectForUse(item,true), null,2));
  }
  
  const refreshList = () => {
	  if(window.hpayRefreshListView){
		window.hpayRefreshListView();
	  }
  };
  
  const closeDetailsDialog = () => {
	setDetailsDialogOpen(false);  
  };
  
  const openDetailsDialog = (title, content, actions, size) => {
    
	setDetailsDialogSize(size || "md");
	setDetailsDialogTitle(title || "");
	setDetailsDialogContent(content || "");
	setDetailsDialogActions(actions || []);
	
	setDetailsDialogOpen(true);  
  };
  
  const compileMethods = (which) => {
	  
	  if(!window.__sitesData)
		  return;
	  
	  if(!window.__sitesData[sessionStorage.site_id])
		  return;
	  
	  if(!which || which == "payment"){
		  if(window.__sitesData[sessionStorage.site_id].payment_methods && window.__sitesData[sessionStorage.site_id].site_payment_methods){
			  for(var mid in window.__sitesData[sessionStorage.site_id].site_payment_methods){
				  if(window.__sitesData[sessionStorage.site_id].site_payment_methods.hasOwnProperty(mid)){
					  var mpay = window.__sitesData[sessionStorage.site_id].site_payment_methods[mid];
					  if(mpay.PaymentMethod){
						  if(mpay.PaymentMethod.Data){
							
							compilableMethods.forEach(f => {
								if(mpay.PaymentMethod.Data[f]){
								  try{	
									eval("mpay." + f + " = " + mpay.PaymentMethod.Data[f]);
								  }catch(eex){}
								}
								if(!mpay[f]){
									mpay[f] = function(obj){};
								}
							});
							
							try{
								mpay.initActions();
							}catch(ex){
								//
							}
						  }
					  }
				  }
			  }
		  }
	  }
	  
	  
	  if(!which || which == "shipping"){
		  if(window.__sitesData[sessionStorage.site_id].shipping_methods && window.__sitesData[sessionStorage.site_id].site_shipping_methods){
			  for(var mid in window.__sitesData[sessionStorage.site_id].site_shipping_methods){
				  if(window.__sitesData[sessionStorage.site_id].site_shipping_methods.hasOwnProperty(mid)){
					  var mship = window.__sitesData[sessionStorage.site_id].site_shipping_methods[mid];
					  if(mship.ShippingMethod){
						  if(mship.ShippingMethod.Data){
							compilableMethods.forEach(f => {
								if(mship.ShippingMethod.Data[f]){
								  try{	
									eval("mship." + f + " = " + mship.ShippingMethod.Data[f]);
								  }catch(eex){}
								}
								if(!mship[f]){
									mship[f] = function(obj){};
								}
							});
							
							try{
								mship.initActions();
							}catch(ex){
								//
							}
						  }
					  }
				  }
			  }
		  }
	  }
	  
	  
	  if(!which || which == "fiscal"){
		  if(window.__sitesData[sessionStorage.site_id].fiscal_methods && window.__sitesData[sessionStorage.site_id].site_fiscal_methods){
			  for(var mid in window.__sitesData[sessionStorage.site_id].site_fiscal_methods){
				  if(window.__sitesData[sessionStorage.site_id].site_fiscal_methods.hasOwnProperty(mid)){
					  var mfis = window.__sitesData[sessionStorage.site_id].site_fiscal_methods[mid];
					  if(mfis.FiscalMethod){
						  if(mfis.FiscalMethod.Data){
							compilableMethods.forEach(f => {
								if(mfis.FiscalMethod.Data[f]){
								  try{	
									eval("mfis." + f + " = " + mfis.FiscalMethod.Data[f]);
								  }catch(eex){}
								}
								if(!mfis[f]){
									mfis[f] = function(obj){};
								}
							});
							
							try{
								mfis.initActions();
							}catch(ex){
								//
							}
						  }
					  }
				  }
			  }
		  }
	  }
	  
	  if(!which){
		  if(window.__sitesData[sessionStorage.site_id].payment_methods && window.__sitesData[sessionStorage.site_id].site_payment_methods)
			setSitePaymentMethods(window.__sitesData[sessionStorage.site_id].site_payment_methods);
		  
		  if(window.__sitesData[sessionStorage.site_id].shipping_methods && window.__sitesData[sessionStorage.site_id].site_shipping_methods)
			setSiteShippingMethods(window.__sitesData[sessionStorage.site_id].site_shipping_methods);
		  
		  if(window.__sitesData[sessionStorage.site_id].fiscal_methods && window.__sitesData[sessionStorage.site_id].site_fiscal_methods)
			setSiteFiscalMethods(window.__sitesData[sessionStorage.site_id].site_fiscal_methods);
	  }
  };
  
  const loadDefinitions = (onComplete) => {
	  
	  if(window.__sitesData){
		  if(window.__sitesData[sessionStorage.site_id]){
			  if(window.__sitesData[sessionStorage.site_id].done){
				  if(onComplete){
					  onComplete();
					  return;
				  }  
			  }
		  }
	  }else{
		  window.__sitesData = {};
	  }
	  
	  if(!sessionStorage.site_id || !sessionStorage.company_id)
		  return;
	  
	  if(!window.__sitesData[sessionStorage.site_id]){
		  window.__sitesData[sessionStorage.site_id] = {done: false};
	  }
	  
	  setIsLoading(true);
	  
	  dataProvider.getList("company_site_payment_methods", { pagination: { page: 1, perPage: 99999 }, sort: { field: 'id', order: 'ASC' }, filter: {
		  CompanySiteId: sessionStorage.site_id
	  }})
            .then(res => {
			 
			  let payment_methods = {};
			  let site_payment_methods = {};	
			  
			  res.data.forEach(pm => {
				  
				  try{
					pm.Data = JSON.parse(pm.Data);
				  }catch(smex){
					  //
				  }
				  
				  
				  payment_methods[pm.PaymentMethodId] = pm.PaymentMethod;
				  site_payment_methods[pm.id] = pm;
			  });	
			  
			  window.__sitesData[sessionStorage.site_id].payment_methods = payment_methods;
			  window.__sitesData[sessionStorage.site_id].site_payment_methods = site_payment_methods;
			  
			  compileMethods("payment");
			  
			  setPaymentMethods(payment_methods);
			  setSitePaymentMethods(site_payment_methods);
			  
			  window.__sitesData[sessionStorage.site_id]._payment_done = true;
			  
			  if(window.__sitesData[sessionStorage.site_id]._payment_done && window.__sitesData[sessionStorage.site_id]._shipping_done && window.__sitesData[sessionStorage.site_id]._fiscal_done){
				  
				  window.__sitesData[sessionStorage.site_id].done = true;
				  if(onComplete){
					onComplete();
				  }
			  }
				
			}).catch(err => {
				
				if(err && err.body && err.body.error && /^401 /.test(err.body.error)){
					let event = new Event("hpay-auth-expired");
					window.dispatchEvent(event);
				}
				
				setAlertMessage(t("Could not load payment methods definition"));
				setAlertType("error");
				setAlertOpen(true);
				
				window.__sitesData[sessionStorage.site_id]._payment_done = true;
				window.__sitesData[sessionStorage.site_id].payment_methods = {};
				window.__sitesData[sessionStorage.site_id].site_payment_methods = {};
			  
			    if(window.__sitesData[sessionStorage.site_id]._payment_done && window.__sitesData[sessionStorage.site_id]._shipping_done && window.__sitesData[sessionStorage.site_id]._fiscal_done){
				  
				  window.__sitesData[sessionStorage.site_id].done = true;
				  if(onComplete){
					onComplete();
				  }
			    }
			  
			});
	  
	  dataProvider.getList("company_site_shipping_methods", { pagination: { page: 1, perPage: 99999 }, sort: { field: 'id', order: 'ASC' }, filter: {
		  CompanySiteId: sessionStorage.site_id
		  
	  } })
            .then(res => {
			 
			  let shipping_methods = {};
			  let site_shipping_methods = {};	
			  
			  res.data.forEach(sm => {
				  
				  try{
					sm.Data = JSON.parse(sm.Data);
				  }catch(smex){
					  //
				  }
				 
				  
				  shipping_methods[sm.ShippingMethodId] = sm.ShippingMethod;
				  site_shipping_methods[sm.id] = sm;
			  });	
			  
			  window.__sitesData[sessionStorage.site_id].shipping_methods = shipping_methods;
			  window.__sitesData[sessionStorage.site_id].site_shipping_methods = site_shipping_methods;
			  
			  compileMethods("shipping");
			   
			  setShippingMethods(shipping_methods);
			  setSiteShippingMethods(site_shipping_methods);
			  
			  window.__sitesData[sessionStorage.site_id]._shipping_done = true;
			  
			  if(window.__sitesData[sessionStorage.site_id]._shipping_done && window.__sitesData[sessionStorage.site_id]._shipping_done && window.__sitesData[sessionStorage.site_id]._fiscal_done){
				  
				  window.__sitesData[sessionStorage.site_id].done = true;
				  if(onComplete){
					onComplete();
				  }
			  }
				
			}).catch(err => {
				
				if(err && err.body && err.body.error && /^401 /.test(err.body.error)){
					let event = new Event("hpay-auth-expired");
					window.dispatchEvent(event);
				}
				
				setAlertMessage(t("Could not load shipping methods definition"));
				setAlertType("error");
				setAlertOpen(true);
				
				window.__sitesData[sessionStorage.site_id]._shipping_done = true;
				window.__sitesData[sessionStorage.site_id].shipping_methods = {};
				window.__sitesData[sessionStorage.site_id].site_shipping_methods = {};
			  
			    if(window.__sitesData[sessionStorage.site_id]._shipping_done && window.__sitesData[sessionStorage.site_id]._shipping_done && window.__sitesData[sessionStorage.site_id]._fiscal_done){
				  
				  window.__sitesData[sessionStorage.site_id].done = true;
				  if(onComplete){
					onComplete();
				  }
			    }
			  
			});
			
	  dataProvider.getList("company_site_fiscal_methods", { pagination: { page: 1, perPage: 99999 }, sort: { field: 'id', order: 'ASC' }, filter: {
		  
		  CompanySiteId: sessionStorage.site_id
		  
	  } })
            .then(res => {
			 
			  let fiscal_methods = {};
			  let site_fiscal_methods = {};	
			  
			  res.data.forEach(fm => {
				  
				  try{
					fm.Data = JSON.parse(fm.Data);
				  }catch(smex){
					  //
				  }
				  
				  fiscal_methods[fm.FiscalMethodId] = fm.FiscalMethod;
				  site_fiscal_methods[fm.id] = fm;
			  });	
			  
			  
			  
			  window.__sitesData[sessionStorage.site_id].fiscal_methods = fiscal_methods;
			  window.__sitesData[sessionStorage.site_id].site_fiscal_methods = site_fiscal_methods;
			  
			  compileMethods("fiscal");
			  
			  setFiscalMethods(fiscal_methods);
			  setSiteFiscalMethods(site_fiscal_methods);
			  
			  window.__sitesData[sessionStorage.site_id]._fiscal_done = true;
			  
			  if(window.__sitesData[sessionStorage.site_id]._fiscal_done && window.__sitesData[sessionStorage.site_id]._shipping_done && window.__sitesData[sessionStorage.site_id]._fiscal_done){
				 
				  window.__sitesData[sessionStorage.site_id].done = true;
				  if(onComplete){
					onComplete();
				  }
			  }
				
			}).catch(err => {
				
				if(err && err.body && err.body.error && /^401 /.test(err.body.error)){
					let event = new Event("hpay-auth-expired");
					window.dispatchEvent(event);
				}
				
				setAlertMessage(t("Could not load fiscal/integration methods definition"));
				setAlertType("error");
				setAlertOpen(true);
				
				window.__sitesData[sessionStorage.site_id]._fiscal_done = true;
				window.__sitesData[sessionStorage.site_id].fiscal_methods = {};
				window.__sitesData[sessionStorage.site_id].site_fiscal_methods = {};
			  
			    if(window.__sitesData[sessionStorage.site_id]._fiscal_done && window.__sitesData[sessionStorage.site_id]._shipping_done && window.__sitesData[sessionStorage.site_id]._fiscal_done){
				  
				  window.__sitesData[sessionStorage.site_id].done = true;
				  if(onComplete){
					onComplete();
				  }
			    }
			  
			});	
	  
  };
  
  const readyForLoad = () => {
	  return window.__sitesData && window.__sitesData[sessionStorage.site_id] && window.__sitesData[sessionStorage.site_id].done;
  };
  
  const loadData = (lpage, lperpage) => {
	  
	
    if (sessionStorage.site_id) {
		
	  if(readyForLoad()){
		  
		let offset_min = new Date().getTimezoneOffset();	
		let from = new Date(new Date(fromDate).getTime() - (offset_min * 60000));
		let to = new Date(new Date(toDate).getTime() - (offset_min * 60000));
		
		let searchQ = undefined;
		
		if(search && search.trim()){
			searchQ = {OR: JSON.stringify([{Uid:{op:"like", value: "%" + search + "%"}}, {Status:{op:"like", value: "%" + search + "%"}} ,{Name:{op:"like", value: "%" + search + "%"}}, {Data:{op:"like", value: "%" + search + "%" }}])};
		}

		setIsLoading(true);
		
		let uid_filter       = null; 
		let uid_filter_prop  = null;
		let uid_filter_value = null;
		
		if(window._singleId){
			let id = window._singleId;
			if(/\:/.test(id)){
				uid_filter = {};
				uid_filter_prop  = id.split(":")[0];
				uid_filter_value = id.split(":")[1]; 
				uid_filter[uid_filter_prop] = id.split(":")[1]; 
			}else{
				uid_filter = {id: parseInt(id)};
				uid_filter_prop  = "id";
				uid_filter_value = parseInt(id); 
				setSelected([uid_filter_value]);
			}
		}
		
		dataProvider.getList("company_site_orders", { pagination: { page: (lpage !== undefined ? lpage :  page), perPage: (lperpage !== undefined ? lperpage : rowsPerPage) }
													, sort: { field: orderBy, order: order }
													, filter: uid_filter ? uid_filter : { 
			CompanySiteId: sessionStorage.site_id,
			CreatedAt: JSON.stringify({"op": "between", "value": [from.toISOString(),to.toISOString()]}),
			...searchQ
		}})
        .then(res => {
			
		  setTotalItems(res.total);	
		  
		  if(lpage !== undefined){
			 setPage(lpage);
		  }
		  
		  if(lperpage !== undefined){
			 setRowsPerPage(lperpage); 
		  }
		  
		  setIsLoading(false);	
		  let json_props = ["Data","SplitPayData","ShippingData","FiscalData"];	
          
		  for(let i = 0 ; i < res.data.length; i++){
			  for(let j = 0; j < json_props.length; j++){
				  let prop = json_props[j];
				  try{
					if(res.data[i][prop]){  
						res.data[i][prop] = JSON.parse(res.data[i][prop]);
					}else{ 
						res.data[i][prop] = {};
					}
				  }catch(ex){
					  
				  }
			  }
			  
			  if(uid_filter){
				  if(res.data[i][uid_filter_prop] == uid_filter_value){
					  setSelected([res.data[i].id]);
				  }
			  }
		  }
		  
		  window._orders = res.data;
		  setItems(res.data);
		  setEmptyMessage(t("Nothing found"));
		  
        }).catch(err => {
				
				if(err && err.body && err.body.error && /^401 /.test(err.body.error)){
					let event = new Event("hpay-auth-expired");
					window.dispatchEvent(event);
				}
			
				setIsLoading(false);
				setAlertMessage(t("Error during data loading!"));
				setAlertType("error");
				setAlertOpen(true);
			});
			
		  
	  }else{
		  loadDefinitions(function(){
			loadData(lpage, lperpage);
		  });	
	  }
	  
    }
    else {
      setAlertMessage(t("Please select a POS!"));
      setAlertType("info");
      setAlertOpen(true);
    }
  };
  
  const loadDataWithDelay = () => {
	setTimeout(loadData, 1500);  
  };
  
  
  const object_if_json_string = (str) => {
	if(!str){
		return {};
	}
	
	if (!(typeof str === 'string' || str instanceof String)){
	  return str;
    }
	
	try{
		return JSON.parse(str);
	}catch(ex){
		return {};
	}
  };

  const onOrderCreated = (evt) => {
	  try{
		  if(toDate.getTime() + 14400000 > (new Date()).getTime() && !search){
			  if(evt.Order.CompanySiteId == sessionStorage.site_id){
				  
				  evt.Order = prepareObjectForUse(evt.Order);
				  if(!window._orders)
					  window._orders = [];
				  let ind = window._orders.findIndex(itm => itm.id == evt.Order.id);
				  if(ind > -1){
					window._orders[ind] = Object.assign(window._orders[ind], evt.Order);  
				  }else{
					window._orders = [evt.Order].concat(window._orders);
				  }
				  setItems(window._orders || []);
				  setTimeout(refreshList,150);
			  }
		  }
	  }catch(ex){
		  console.error(ex);
	  }
  };
  
  const onOrderUpdated = (evt) => {
	  try{
		  if(evt.Order){
			  if(evt.Order.CompanySiteId == sessionStorage.site_id){
				  evt.Order = prepareObjectForUse(evt.Order);
				  
				  if(!window._orders)
					  window._orders = [];
				  
				  let ind = window._orders.findIndex(itm => itm.id == evt.Order.id);
				  if(ind > -1){
					  window._orders[ind] = Object.assign(window._orders[ind], evt.Order);
					  setItems(window._orders || []);
					  setTimeout(refreshList,150);
				  }
			  }
		  }
	  }catch(ex){
		  console.error(ex);
	  }
  };
  
  useEffect(() => {
	
	window._refreshNo = refreshNo || 2; 	
	window.hpayRefreshListView = function(){
		try{
			window._refreshNo++;
			setRefreshNo(window._refreshNo);
		}catch(ex){
			
		}
	}; 
	
	window._singleId = (params || {}).id || "";
	loadData(undefined, undefined);
	
	window.addEventListener("hpay-site-changed", loadDataWithDelay, false);
	window.addEventListener("hpay-order-updated", onOrderUpdated, false);
	window.addEventListener("hpay-order-created", onOrderCreated, false);
	
	compileMethods();
	
	return () => {
		window.removeEventListener("hpay-site-changed", loadDataWithDelay, false);
		window.removeEventListener("hpay-order-updated", onOrderUpdated, false);
		window.removeEventListener("hpay-order-created", onOrderCreated, false);
		
	};
    // eslint-disable-next-line
  }, [])

  return (
    <Page title="Orders | Holest">
      <Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={AlertClose}>
        <Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={5} spacing={2}>
          <Typography variant="h4" gutterBottom>
            {t("Orders")}: 
          </Typography>
		  <LocalizationProvider dateAdapter={AdapterDayjs} >
		  
		  <DateTimePicker
			  label={t("From date/time")}
			  ampm={false}
			  inputFormat="YYYY-MM-DD HH:mm"
			  value={fromDate}
			  renderInput={(props) => <TextField {...props} />}
			  onChange={(newValue) => setFromDate(newValue)}
		  />
		  </LocalizationProvider>
		  
		  <LocalizationProvider dateAdapter={AdapterDayjs} >
		  <DateTimePicker
			  label={t("To date/time")}
			  ampm={false}
			  inputFormat="YYYY-MM-DD HH:mm"
			  value={toDate}
			  renderInput={(props) => <TextField {...props} />}
			  onChange={(newValue) => setToDate(newValue)}
			/>
		  </LocalizationProvider>
		  
		  <TextField 
			label={t("search...")}
			value={search}
			onChange={(e) => setSearch(e.target.value)}
			onKeyUp= {(e) => {
					if (e.key === 'Enter') {
					  window._singleId = "";	
					  loadData();
					}
				}}
		  />
		  
		  <LoadingButton
				size="large"
				type="submit"
				color="secondary"
				endIcon={<RefreshIcon />}
				onClick={(e) => {
					
					window._singleId = "";	
					loadData();
					
				}}
				variant="contained"
				loading={isLoading}
			>
				{t("Load")}
			</LoadingButton>


		<Dialog open={detailsDialogOpen} fullWidth={true} maxWidth={detailsDialogSize}>
            <DialogTitle><span id="details_dialog_title">{detailsDialogTitle}</span></DialogTitle>
            <DialogContent>
			  <div id="details_dialog_above_content">
			    
			  </div>
			  <div>
				  <pre>
				  {detailsDialogContent}
				  </pre>
			  </div>
              <div id="details_dialog_content">
			    
			  </div>
		    </DialogContent>
            <DialogActions>
			  {detailsDialogActions.map((item, key ) => {
				  
				   return (
						<Button size='small' key={key} onClick={(e) => {item.Run(window.currentObject)}} variant="contained" >{item.Caption}</Button>
				   )
				  
			  })}
              <Button onClick={(e) => {closeDetailsDialog()} }>{t("Close")}</Button>
            </DialogActions>
		  </Dialog>
        </Stack>

        <Card>
		  <Scrollbar>
		    
			<Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
				{(Object.values(sitePaymentMethods) || []).map((pm, key) => {
					let actions = pm.orderListActions();
					if(actions && actions.length){
						return actions.map((item, subkey) => {
									  return typeof item == "string" ? <span key={subkey}>{item}</span> : ( item.actions ? (
												<RowButtons key={subkey} Items={item.actions} Caption={t(item.Caption)} />
											  ) :(
												// eslint-disable-next-line
												<Button size='small' key={subkey} onClick={(e) => {item.Run(dataProvider, fromDate, toDate, search)}} variant="contained" >{item.Caption}</Button>
											  )
									  )
									})
					}
					return null;
				})}	
				
				{(Object.values(siteFiscalMethods) || []).map((fm, key) => {
					let actions = fm.orderListActions();
					if(actions && actions.length){
						return actions.map((item, subkey) => {
									  return typeof item == "string" ? <span key={subkey}>{item}</span> : ( item.actions ? (
												<RowButtons key={subkey} Items={item.actions} Caption={t(item.Caption)} />
											  ) :(
												// eslint-disable-next-line
												<Button size='small' key={subkey} onClick={(e) => {item.Run(dataProvider, fromDate, toDate, search)}} variant="contained" >{item.Caption}</Button>
											  )
									  )
									})
					}
					return null;
					
				})}	
				
				{(Object.values(siteShippingMethods) || []).map((sm, key) => {
					let actions = sm.orderListActions();
					if(actions && actions.length){
						return actions.map((item, subkey) => {
									  return typeof item == "string" ? <span key={subkey}>{item}</span> : ( item.actions ? (
												<RowButtons key={subkey} Items={item.actions} Caption={t(item.Caption)} />
											  ) :(
												// eslint-disable-next-line
												<Button size='small' key={subkey} onClick={(e) => {item.Run(dataProvider, fromDate, toDate, search)}} variant="contained" >{item.Caption}</Button>
											  )
									  )
									})
					}
					return null;
				})}	
			</Stack>
		    <TableContainer sx={{ minWidth: 800 }}>
              <Table className={"items-table items-table-" + refreshNo} >
			  
				
				<TableHead>
					<TableRow>
						<TableCell>{t("Uid")}</TableCell> 
						<TableCell>{t("Ident.")}</TableCell> 
						<TableCell>{t("Created On")}</TableCell> 
						<TableCell>{t("Amount")}</TableCell> 
						<TableCell>{t("Status")}</TableCell> 
						<TableCell>{t("Customer")}</TableCell> 
						<TableCell>{t("Payment")}</TableCell> 
						<TableCell>{t("Shipping")}</TableCell> 
						<TableCell>{t("Fiscal")}</TableCell>
						<TableCell>{t("Split pay")}</TableCell>						
					</TableRow>
				</TableHead>
				
                <TableBody>
                  {Items.filter(row => !!row && row.id).map((row, key) => {
                      //const { Id, paymentid, Type, Status, UpdatedAt, data } = row;
                      
					  const isItemSelected = selected.indexOf(row.id) !== -1;
					  let actions = [];
					  
					  if(isItemSelected && row.CompanySitePaymentMethodId){
						if(sitePaymentMethods[row.CompanySitePaymentMethodId]){
							try{
								actions = sitePaymentMethods[row.CompanySitePaymentMethodId].orderActions(row) || [];
							}catch(ex){
								//
							}
						}
					  }
					  
					  if(isItemSelected){
					      if(siteFiscalMethods){
							  for(let key in siteFiscalMethods){
								  if(siteFiscalMethods.hasOwnProperty(key)){
									  try{
										 let factions = siteFiscalMethods[key].orderActions(row);
										 if(factions && factions.length){
											actions = actions.concat(factions); 
										 }
									 }catch(ex){
										 
									 } 
								  }
							  }
						  }
						  
						  if(siteShippingMethods){
							  for(let key in siteShippingMethods){
								  if(siteShippingMethods.hasOwnProperty(key)){
									  try{
										 let sactions = siteShippingMethods[key].orderActions(row);
										 if(sactions && sactions.length){
											actions = actions.concat(sactions); 
										 }
									 }catch(ex){
										 
									 } 
								  }
							  }
						  }
					  }
					  
					  /*
					  siteFiscalMethods.map((sm,k) => {
						 try{
							 let sactions = sm.orderActions(row);
							 if(sactions && sactions.length){
								actions = actions.concat(); 
							 }
						 }catch(ex){
							 
						 } 
					  });
					  */
					  
					  if(isItemSelected){
						  window.currentObject = row;
					  }
					  
					  let customer_info   = "";
					  let payment_info    = "";
					  
					  let shipping_info   = "";
					  let fiscal_info     = "";
					  let splitpay_info   = "";
					  
					  if(row.Data && row.Data.billing){
						  customer_info = (row.Data.billing.email || "") + "/ " + (row.Data.billing.first_name || "") + " " + (row.Data.billing.last_name || "")
					  }
					  
					  if(row.CompanySitePaymentMethodId){
						  if(sitePaymentMethods[row.CompanySitePaymentMethodId]){
							  try{
									payment_info = sitePaymentMethods[row.CompanySitePaymentMethodId].Data.parameters.Name;
							  }catch(ex){
								  //
							  }
						  }
					  }
					  
					  // if(row.Data && row.Data.payment){
						  // payment_info = row.Data.payment.status || "";
					  // }
					  
					  if(row.ShippingData){
						  shipping_info = row.ShippingData.status || "";
					  }
					  
					  if(row.FiscalData){
						  fiscal_info = row.FiscalData.status || "";
					  }
					  
					  if(row.SplitPayData){
						  splitpay_info = row.SplitPayData.status || "";
					  }
					  
					  let pstat = row.Status.match(/\s?(PAYMENT:[^\s]*)/) || "";
					  if(pstat){
						  pstat = pstat[0];
					  }
					  
					  let fstat = row.Status.match(/([^\s]*FISCAL:[^\s]*)/) || "";
					  if(fstat){
						  fstat = fstat[0];
					  }
					  
					  let sstat = row.Status.match(/([^\s]*SHIPPING:[^\s]*)/) || "";
					  if(sstat){
						  sstat = sstat[0];
					  }
					  
					  
					  return [
                        <TableRow
                          hover
                          key={key}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}

                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, Id)}
                            />
                          </TableCell> */}
                          <TableCell onClick={(e) => setSelected([row.id])} component="th" scope="row">
                            <Typography onClick={(e) => { itemEdit(row) }} variant="span" style={{color:"#094374", cursor:"pointer"}} noWrap>
                              {row.Uid}
                            </Typography>
                          </TableCell>
						  
						  <TableCell onClick={(e) => setSelected([row.id])} align="left">{row.Name}</TableCell>
                          <TableCell align="left" onClick={(e) => setSelected([row.id])}>
						   {String(row.CreatedAt).substring(0,16).replace("T"," ")}
						  </TableCell>
						  <TableCell onClick={(e) => setSelected([row.id])} align="left">{row.Amount} {row.Currency}</TableCell>
						  <TableCell align="left" onClick={(e) => setSelected([row.id])}>{ pstat }</TableCell>
						  <TableCell align="left" onClick={(e) => setSelected([row.id])}>
							  {customer_info}
						  </TableCell>
						  <TableCell align="left" onClick={(e) => setSelected([row.id])}>
							  {payment_info}
						  </TableCell>
						  <TableCell align="left" onClick={(e) => setSelected([row.id])}>
							  {sstat} {shipping_info}
						  </TableCell>
						  <TableCell align="left" onClick={(e) => setSelected([row.id])}>
							  {fstat} {fiscal_info}
						  </TableCell>
						  <TableCell align="left" onClick={(e) => setSelected([row.id])}>
						      {splitpay_info}
						  </TableCell>
                        </TableRow>].concat( (isItemSelected && selected.length === 1) ? [
						,<TableRow key={key + "_details"} selected={isItemSelected}
                          aria-checked={isItemSelected}>
							<TableCell colSpan={10}>
								<Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="left" className='actions-stack'>
								  <span>
								    <Button onClick={() => {navigator.clipboard.writeText(JSON.stringify({"order": prepareObjectForUse(row,true)}, null, 2))}} size='small' style={{minWidth: "20px"}} key="details-copy"  variant="contained" >📄</Button>									
									<Button onClick={(e) => { itemEdit(row) }} size='small' key="details"  variant="contained" >{t("Order details...")}</Button>
								  </span>
								  <span>
								  {(actions && actions.length) ?
								  <label>{t("ACTIONS")}: </label> : null}
								  {
									actions.map((item, subkey) => {
									  return typeof item == "string" ? <span key={subkey}>{item}</span> : ( item.actions ? (
												<RowButtons key={subkey} Items={item.actions} Caption={t(item.Caption)} />
											  ) :(
												// eslint-disable-next-line
												<Button size='small' key={subkey} onClick={(e) => {item.Run(row)}} variant="contained" >{item.Caption}</Button>
											  )
									  )
									})
								  }
								  </span>
								</Stack>
							</TableCell>
						</TableRow>
						] : [])
                    })}
                  {!Items.length && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={10} >{emptyMessage}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 1000]}
            component="div"
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
	  
	  
	  
	  
	  
    </Page>
  );
}
