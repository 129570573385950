import PropTypes from 'prop-types';
import { useState, useEffect, forwardRef } from 'react';
import { useHistory} from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import configData from "../config.json";
import common from "../common.js";

export default function SessionControl() {
  const {hpbtoa, hpatob} = common;	
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();	
  
  let qs = queryString.parse(location.search);
  
  if(qs.addsite){
	  delete localStorage.hpay_last_company_id;
	  delete localStorage.hpay_last_site_id;
	  delete sessionStorage.CurrentSite;
	  delete sessionStorage.CurrentCompany;
	  delete sessionStorage.site_id;
	  delete sessionStorage.company_id;
	
	  sessionStorage.addsite = qs.addsite;
  }
  
  if(qs.addsite_notify_url){
	  sessionStorage.addsite_notify_url = qs.addsite_notify_url;
  }
  
  if(qs.wmanage){
	  
	  sessionStorage.windowedby     = qs.origin;
	  sessionStorage.control_origin = qs.origin;
	  
	  if(qs.wmanage == "app"){
		sessionStorage.windowedby = "";
	  }
	  
	  if(qs.company_id){
		sessionStorage.company_id = qs.company_id;
	  }
	  
	  if(qs.site_id){
		sessionStorage.site_id    = qs.site_id;
	  }
	  
	  sessionStorage.gotolink = "/" + qs.wmanage.replace(/^\//,"");
  }
  
  if(sessionStorage.addsite){
	  
	sessionStorage.windowedby      = sessionStorage.addsite;
	sessionStorage.control_origin  = sessionStorage.addsite;
	sessionStorage.windowed        = true;
	
  }
  
  const onConnectMessage = (event) => {
	  if(!(sessionStorage.addsite || sessionStorage.control_origin)) return;
	  if (event.origin.indexOf((sessionStorage.addsite || sessionStorage.control_origin).split("/")[0]) === -1) return;
	  
	  if(event.data){
		  if(event.data.command == "addsite_status"){
			  
			  if(!window.addsite_source){
				  window.addsite_source = event.source;
				  window.addsite_origin = event.origin;
			  }
			  
			  let addsite_status = "";
			  if(!sessionStorage.AccessToken){
				  addsite_status = t("Waiting for authorization...");
			  }else{
				  if(!sessionStorage.company_id){
					  addsite_status = t("Company selection/registration...");
				  }else{
					  addsite_status = t("Site selection/registration...");
				  }
			  }
			  
			  let msg = {
					addsite_status_message: addsite_status
			  };
			  
			  if(sessionStorage.addedsite){
				  msg.addsite_status_message = t("Connected!");
				  msg.addedsite = sessionStorage.addedsite;
			  }
			  
			  event.source.postMessage(msg,	event.origin );
		  }else if(event.data.command == "navigate"){
			  history.push("/" + event.data.link.replace(/^\//,"") );
			  try{
				  window.focus();
			  }catch(ex){
				  
			  }
		  }else if(event.data.command == "subscribe_pos_updates"){
			  
			  if(!window.pos_updates_subscribers)
				  window.pos_updates_subscribers = [];
			  if(window.pos_updates_subscribers.indexOf(event.source) === -1){
				  window.pos_updates_subscribers.push(event.source);
			  }
			  
			  event.source.postMessage({subscribed_pos_updates: event.data.message_session_uid},event.origin );
			  
		  }else if(event.data.command == "close"){
			  window.close();
		  }
		  
	  }
  };
  
  const onWindowClose = () => {
	   if(window.addsite_source){
		   try{
			   window.addsite_source.postMessage(
					{window_closed: 1},
					window.addsite_origin
				  );
		   }catch(ex){
			   //
		   }
	   }  
  }; 
  
  const onRecordUpdated = (evt) => {
		if(!sessionStorage.addsite && window.pos_updates_subscribers){
			try{
				let node_data = { environment: configData.API_URL.indexOf("sandbox.") > -1 ? "sandbox" : "production"};
				if(evt.Data.endpoint == "companies"){
					node_data.company_id = evt.Data.response.data.id;
				}else if(evt.Data.endpoint == "company_sites"){
					node_data.company_id = evt.Data.request.data.CompanyId;
					node_data.site_id = evt.Data.response.data.id;
				}else if(evt.Data.endpoint == "company_site_payment_methods" || evt.Data.endpoint == "company_site_shipping_methods" || evt.Data.endpoint == "company_site_fiscal_methods" ){
					node_data.company_id = sessionStorage.company_id;
					node_data.site_id = evt.Data.response.data.CompanySiteId;
				}
				
				node_data.endpoint = evt.Data.endpoint;
				node_data.data     = evt.Data.request.data;
				
				window.pos_updates_subscribers.forEach(source => source.postMessage({hpay_pos_data_update: ("update-" + (new Date()).getTime()),...node_data},"*"));	
			}catch(ex){
				
			}
		}
  };
  
  const onRecordCreated = (evt) => {
		if(!sessionStorage.addsite && window.pos_updates_subscribers){
			try{
				let node_data = { environment: configData.API_URL.indexOf("sandbox.") > -1 ? "sandbox" : "production"};
				if(evt.Data.endpoint == "companies"){
					node_data.company_id = evt.Data.response.data.id;
				}else if(evt.Data.endpoint == "company_sites"){
					node_data.company_id = evt.Data.response.data.CompanyId;
					node_data.site_id = evt.Data.response.data.id;
				}else if(evt.Data.endpoint == "company_site_payment_methods" || evt.Data.endpoint == "company_site_shipping_methods" || evt.Data.endpoint == "company_site_fiscal_methods" ){
					node_data.company_id = sessionStorage.company_id;
					node_data.site_id = evt.Data.response.data.CompanySiteId;
				}
				
				node_data.endpoint = evt.Data.endpoint;
				node_data.data     = evt.Data.request.data;
				
				window.pos_updates_subscribers.forEach(source => source.postMessage({hpay_pos_data_update: ("create-" + (new Date()).getTime()),...node_data},"*"));	
			}catch(ex){
				
			}	
		}
  };
  
  const onRecordDeleted = (evt) => {
		if(!sessionStorage.addsite && window.pos_updates_subscribers){
			try{
				let node_data = { environment: configData.API_URL.indexOf("sandbox.") > -1 ? "sandbox" : "production"};
				if(evt.Data.endpoint == "companies"){
					node_data.company_id = evt.Data.response.data.id;
				}else if(evt.Data.endpoint == "company_sites"){
					node_data.company_id = sessionStorage.company_id;
					node_data.site_id    = evt.Data.response.data.id;
				}else if(evt.Data.endpoint == "company_site_payment_methods" || evt.Data.endpoint == "company_site_shipping_methods" || evt.Data.endpoint == "company_site_fiscal_methods" ){
					node_data.company_id = sessionStorage.company_id;
					node_data.site_id = sessionStorage.site_id;
				}
				
				node_data.endpoint = evt.Data.endpoint;
				node_data.data     = evt.Data.request.data;
				
				window.pos_updates_subscribers.forEach(source => source.postMessage({hpay_pos_data_update: ("delete-" + (new Date()).getTime()),...node_data},"*"));	
			}catch(ex){
				
			}	
		}
  };
  
  const onOTSevent = (evt) => {
	  if(window.pos_updates_subscribers){
		try{
			let node_data = { 
				environment: configData.API_URL.indexOf("sandbox.") > -1 ? "sandbox" : "production",
				company_id: evt.CompanyId,
				site_id: evt.SiteId,
				event: evt.HPayEventName
			};
			
			let et = evt.HPayEventName.split("-");
			node_data.what   = et[1];
			node_data.action = et[2];
			
			if(node_data.what == "order"){
				node_data.endpoint = "orders/Uid:" + evt.Order.Uid;
				node_data.data     = evt.Order;
			}else if(node_data.what == "transaction"){
				node_data.endpoint = "transactions/Uid:" + evt.Transaction.Uid;
				node_data.data     = evt.Transaction;
			}else if(node_data.what == "subscription"){
				node_data.endpoint = "subscriptions/Uid:" + evt.Subscription.Uid;
				node_data.data     = evt.Subscription;
			}
			
			window.pos_updates_subscribers.forEach(source => source.postMessage({hpay_ost_event: evt.HPayEventName ,...node_data},"*"));	
		}catch(ex){
			
		}	
	  }
  };
  
  useEffect(() => {
	window.__hpay_panel_loaded = true;
	  
	let conenct_handler_added = false;  
	let ucd_added = false;
	
	if(sessionStorage.addsite || sessionStorage.control_origin){
	  window.addEventListener("message",onConnectMessage,false);
	  window.addEventListener('beforeunload', onWindowClose, false);
	  conenct_handler_added = true;
    }

    if(sessionStorage.AccessToken){
		
		window.addEventListener("hpay-record-updated",onRecordUpdated,false);
		window.addEventListener("hpay-record-created",onRecordCreated,false);
		window.addEventListener("hpay-record-deleted",onRecordDeleted,false);
		
		["order","subscription","transaction"].forEach(what => {
			["created","updated"].forEach(action => {
				window.addEventListener("hpay-" + what + "-" + action, onOTSevent,false);
			});	
		});
		
		ucd_added = true;
	}	
	  
	return () => {
		if(conenct_handler_added){
			window.removeEventListener("message",onConnectMessage,false);
			window.removeEventListener('beforeunload', onWindowClose, false);
		}
		if(ucd_added){
			window.removeEventListener("hpay-record-updated",onRecordUpdated,false);
			window.removeEventListener("hpay-record-created",onRecordCreated,false);
			window.removeEventListener("hpay-record-deleted",onRecordDeleted,false);
			
			["order","subscription","transaction"].forEach(what => {
				["created","updated"].forEach(action => {
					window.removeEventListener("hpay-" + what + "-" + action, onOTSevent,false);
				});	
			});
		}
	};
	
  }, []);	
	
  return null;
}
