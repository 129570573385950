import { useEffect, useState, forwardRef } from 'react';
import Page from '../components/Page';
import { Container, Stack, Typography, CardActionArea, TextField, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import configData from "../config.json";
import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function TicketDetails() {
    const params = useParams();
    const history = useHistory();
	
	const [currentTicket, setCurrentTicket] = useState({User:{}});
	
	const [AlertMessage, setAlertMessage] = useState("success");
    const [AlertType, setAlertType] = useState("success");
    const [AlertOpen, setAlertOpen] = useState(false);
	
	const AlertClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
		setAlertOpen(false);
	};
  

    const [messageContent, setMessageContent] = useState("");
    const [Title, setTitle] = useState("");
    const [TicketEntries, setTicketEntries] = useState("");
    const [Ticketstatus, setTicketstatus] = useState("");
    const [date, setdate] = useState("");
    const { t } = useTranslation();

    const httpClient = (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
        }
        const token = JSON.parse(sessionStorage.AccessToken).Token;
        options.headers.set('Authorization', `${token}`);
        return fetchUtils.fetchJson(url, options);
    };

    const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
    const MainUserId = JSON.parse(sessionStorage.AccessToken).UserId
    // console.log(Email)
    const load = () => {
        const Id = params.id;

        dataProvider.getOne("tickets", { id: Id })
            .then(res => {
				
				
                const data = res.data;
				
				setCurrentTicket(res.data);
				
	            setTitle(data.Title);
                setTicketstatus(data.Closed)
                setdate(data.CreatedAt);
				
				setTicketEntries(res.data.TicketEntries.reverse());
				
		    }).catch(err => {
				
				if(err && err.body && err.body.error && /^401 /.test(err.body.error)){
					let event = new Event("hpay-auth-expired");
					window.dispatchEvent(event);
				}
				
			});

    }
    const back = (params) => {
        history.push('/tickets')
    }

    const ticketdelete = () => {
        dataProvider.delete("tickets", { id: params.id }).then(res => {
            history.push('/tickets')
        })

    }

    const itemdelete = (id) => {
		confirmAlert({
		  title: t('Delete support ticket message') + "?",
		  message: t('Please confirm you want remove message'),
		  buttons: [
			{
			  label: t('Delete message'),
			  onClick: () => {
				  dataProvider.delete('ticket_entry', { id: id })
				  .then(res => {
					setAlertMessage(t("Message deleted"));
					setAlertType("success");
					setAlertOpen(true);
					load();
				  })
				  .catch(error => {
					setAlertMessage(t("Error: message could not be deleted!"));
					setAlertType("error");
					setAlertOpen(true);
				  })
			  }
			},
			{
			  label: t('Cancel'),
			  onClick: () => {}
			}
		  ]
		});
    }
	
    const ticketmarket = () => {
		
		confirmAlert({
		  title: t('Mark resolved') + "?",
		  message: t('Please confirm you want mark this ticket as resolved'),
		  buttons: [
			{
			  label: t('Close ticket'),
			  onClick: () => {
				  dataProvider.update("tickets", { id: params.id, data: { Closed: true } })
				  .then(res => {
						console.log(res);
						load();
				  })
				  .catch(error => {
					setAlertMessage(t("Error: ticket could not be closed!"));
					setAlertType("error");
					setAlertOpen(true);
				  })
			  }
			},
			{
			  label: t('Cancel'),
			  onClick: () => {}
			}
		  ]
		});
		
		
        
    }

    const addMessage = () => {
        const Email = JSON.parse(sessionStorage.UserData).Email;
        dataProvider.create("ticket_entry", { data: { TicketId: params.id, WriterId: MainUserId, Content: messageContent, MailSentUser: Email } })
            .then(res => {
                console.log(res);
				setMessageContent("");
                load();
				
				
				const token = JSON.parse(sessionStorage.AccessToken).Token;
				
				fetch(configData.API_URL + 'supportTicketNotifications', {
					  method:"POST",
					  headers: {
						"Content-Type": "application/json",
						"Authorization": `${token}`
					  },
					  body:JSON.stringify({
						TicketId: params.id,
						language: sessionStorage.language
					  })
				  }).then(raw_response => {
					  return raw_response.json();
				  }).then(response => {
					  //	
				  }).catch(err => {
					  //
				  });
		    });
    }

    useEffect(() => {
        load()
        // eslint-disable-next-line
    }, [])
    return (
        <Page title="Ticket | Holest">
			<Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={AlertClose}>
				<Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
				  {AlertMessage}
				</Alert>
			</Snackbar>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Stack direction="column">
						<Typography variant="h4" gutterBottom>
							{t("Support ticket")}  
						</Typography>
						<Typography variant="p" style={{fontSize:"80%", fontStyle:"italic"}}>
							{t("Raised by")}: {currentTicket.User.Email} | {t("Ticket time")}: {String(date).split(".")[0]} 
						</Typography>
					</Stack>
					
                    <Button
							variant="dark"
							onClick={(e) => back()}
							startIcon={<ExitToAppIcon />}
							color="secondary"
						>
							{t("Back to support tickets list...")}
					  </Button>
					  
                </Stack>
				<Typography variant="h6">
							{t("SUBJECT")}: <span style={{ padding:"4px", display:"inline-block"}}>{Title}</span>
						</Typography>
				
            </Container>
			<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={0}>
				{Ticketstatus !== true ?
				<LoadingButton
					fullWidth
					color="secondary"
					size="small"
					onClick={(e) => ticketmarket()}
					endIcon={"✓"}
					variant="contained"
				>
					{t("Mark Resolved")}
				</LoadingButton> : null}
				
				<LoadingButton
					fullWidth
					color="warning"
					size="small"
					onClick={(e) => ticketdelete()}
					endIcon={<DeleteForeverIcon />}
					variant="contained"
				>
					{t("Delete Support Ticket")}
				</LoadingButton>
			</Stack>
			<hr/>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
							
							
                            <Stack spacing={3}>
                                {
                                    Ticketstatus === true ? <TextField
                                        fullWidth
                                        label={t("Support Ticket Details")}
                                        multiline
                                        value={t("This support ticket is resolved")}
                                        rows={1}
                                        disabled
                                    /> : <TextField
                                        fullWidth
                                        label={t("Message/Issue Description")}
                                        multiline
                                        value={messageContent}
                                        onChange={(e) => setMessageContent(e.target.value)}
                                        rows={3}
                                    />
                                }
								
								{Ticketstatus !== true ?
								<Button
                                    
                                    size="small"
                                    type="button"
                                    color="secondary"
                                    onClick={(e) => addMessage()}
                                    endIcon={<SendIcon />}
                                    variant="contained"
                                >
                                    {t("Send")}
                                </Button> : null}
								
                                {
                                    TicketEntries ? TicketEntries.map((item, key) => {
                                        return (
                                            <div key={key}>
												<hr/>
                                                <span style={{fontSize:"80%", fontStyle:"italic"}}>{item.Writer.Email} {t('at')} {item.CreatedAt.substring(0,19).replace("T"," ")} </span>
                                                
												{item.Writer.id == MainUserId ?
												<Button
                                                    variant="contained"
                                                    size="small"
                                                    style={{ float: "right" }}
                                                    onClick={(e) => itemdelete(item.id)}
                                                    startIcon={<DeleteForeverIcon />}
                                                >
                                                    {t("Delete message")}
                                                </Button> : null}

                                                <div>
                                                    {item.Content}
                                                </div>
												<hr/>
                                            </div>
                                        )
                                    }) : ""
                                }
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Page >
    );
}
