import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Button, IconButton, TableContainer, Table, TableRow, TableBody, TableHead, TableFooter, TableCell } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export default function ObjectTable({ Data, Columns , onDataChange = null, PrimaryIdentifier = null, AllowAddRemoveOffset = 0, MaxRows = 9999}) {
	const { t } = useTranslation();
	const [innerData, setInnerData] = useState(Object.values(Data));
	const [dataValid, setDataValid] = useState(true);
	
	const dataIsArray = () => {
		
		let item_is_array = false;
		if(innerData.length){
			item_is_array = Array.isArray(innerData[0]);
		}else{
			if(Object.keys(Columns)[0] == "0"){
				if(!Object.values(Columns)[0].Property || Object.values(Columns)[0].Property == "0"){
					item_is_array = true;
				}
			}else{
				if(Object.values(Columns)[0].Property){
					item_is_array = Object.values(Columns)[0].Property == "0";
				}	
			}
		}
		return item_is_array;							
	};
	
	const handleCellChange = (rowid, prop, value, newData, externalSet) => {
		
		if(!(rowid === null && prop === null)){
			if(newData){
				newData[rowid][prop] = value;
			}else{
				innerData[rowid][prop] = value;
			}
		}
		
		if(!newData)
			newData = innerData.map(a => a);
		
		setInnerData( newData );
		
		if(onDataChange){
		
			let required_array  = {};
			Object.keys(Columns).map((prop, index) => {
			  let col = Columns[prop];
			  if(col.Property){
				  prop = col.Property;
			  }
			  
			  if(PrimaryIdentifier && prop === PrimaryIdentifier){
				required_array[prop] = true;  
			  }else{
				required_array[prop] = !!col.required;
			  }
			});
			
			let valid = true;
			if(PrimaryIdentifier){
				Object.values(newData).forEach(item => {
					if(PrimaryIdentifier){
						if(!item[PrimaryIdentifier] && String(item[PrimaryIdentifier]) !== "0"){
							valid = false;
						}
					}
					
					if(valid){
						Object.keys(required_array).map(prop =>{
							if(required_array[prop] && (!item[prop] && String(item[prop]) !== "0")){
								valid = false;
							}
						});
					}
				});
			}
			
			setDataValid(valid);
			
			if(valid && !externalSet){
				if(PrimaryIdentifier !== null && PrimaryIdentifier !== undefined && PrimaryIdentifier !== ""){
					let d = {};
					newData.map( itm => {
						d[itm[PrimaryIdentifier]] = itm;
					});
					onDataChange(d);
				}else{
					onDataChange(newData);
				}	
			}
		}
	};
	
	
	
	useEffect(() => {
		try{
			//setInnerData(Data);
			console.log("X")
			
			
		}catch(ex){
			console.error(ex);	
		}
		
    }, [innerData]);
	
	if(Data.length && innerData.length != Data.length){
		//setInnerData(Data);
		handleCellChange(null, null, null,Data, true);
	}
	
	return (
        <>
			
            <TableContainer className="inline-object-table-wrapper" sx={{ minWidth: 800 }}>
              <Table cellPadding={0} cellSpacing={0} className="inline-object-table">
            	<TableHead>
					<TableRow>
				    { Object.values(Columns).concat((!isNaN(AllowAddRemoveOffset) && AllowAddRemoveOffset >= 0) ? [""] : []).map((column, key) =>{
						return (
							<TableCell key={key} style={column.headerStyle??{fontSize:'80%'}} >{column.HeaderText || column}</TableCell>
						)
					})}
				    </TableRow>
				</TableHead>
			    <TableBody className="inline-object-table-body">
				  
                  {Object.keys(innerData).map((rowid, key) => {
					  
					  let type_array      = [];
					  let prop_array      = [];
					  let required_array  = [];
					  let inputProp_array = [];
					  let primary_index   = -1;
					  let readonly_array  = [];
					  
					  Object.keys(Columns).map((prop, index) => {
						  
						  let col = Columns[prop];
						  
						  if(col.Property){
							  prop = col.Property;
							  prop_array.push(prop);
						  }else{
							  prop_array.push(prop);
						  }
						  
						  type_array.push(col.Type || "text"); 
						  inputProp_array.push(col.inputProps || null);
						  
						  if(PrimaryIdentifier && prop === PrimaryIdentifier){
							required_array.push(true);  
							primary_index = index;
						  }else{
							required_array.push(col.required || null);
						  }
						  
						  readonly_array.push(col.readonly || false);
						  
					  });
					  
					  // let remove_index = null;
					  // if((!isNaN(AllowAddRemoveOffset) && AllowAddRemoveOffset >= 0)){
						  // remove_index = val_array.length;
						  // val_array.push("");
						  // type_array.push("--remove--");
					  // }
					  
					  return (
                        <TableRow
                          hover
                          key={key}
                          tabIndex={-1}
                          className='clickable'
                        >
						{
							prop_array.map((prop, index) => {
								let type = type_array[index];
								
								if(type == "checkbox"){
									return (
										<TableCell  key={"cell_" + String(key) + "_" + String(index)} >
											<input 
											       readOnly={readonly_array[index] || (prop == PrimaryIdentifier && AllowAddRemoveOffset >= 0 && key < AllowAddRemoveOffset)}
												   type={"checkbox"} 
												   checked={!!innerData[rowid][prop]} 
												   value={true} 
												   onChange={(e) => handleCellChange( rowid, prop, e.target.checked)} 
												   {...inputProp_array[index]} />
										</TableCell>
									)
								}else{
									return (
										<TableCell  key={"cell_" + String(key) + "_" + String(index)} >
											<input readOnly={readonly_array[index] || (prop == PrimaryIdentifier && AllowAddRemoveOffset >= 0 && key < AllowAddRemoveOffset) } 
											       style={ required_array[index] ? ( (!innerData[rowid][prop] && String(innerData[rowid][prop]) !== "0") ? { border: "1px solid red" } :  {} ) : null } 
												   type={type_array[index] || "text"} 
												   value={innerData[rowid][prop] || ""} 
												   onInput={(e) => handleCellChange( rowid, prop, e.target.value)} 
												   {...inputProp_array[index]} />
										</TableCell>
									)
								}
							})
						}
						
						{
							(key >= AllowAddRemoveOffset) ?
										
								<TableCell key={"cell_" + String(key) + "_remove" } >
									<DeleteForeverIcon onClick={(e) => {
										if(Array.isArray(innerData)){
											innerData.splice(key,1);
											handleCellChange(null,null,null);
											
										}else{
											delete innerData[rowid];
											handleCellChange(null,null,null);
										}
									}} />
								</TableCell>
						:
								<TableCell  key={"cell_" + String(key) + "_remove" } ></TableCell>
						}
						
						
                        </TableRow>
                      );
                    })}
                </TableBody>
				<TableFooter>
				
				  {
					  (!isNaN(AllowAddRemoveOffset) && AllowAddRemoveOffset >= 0) ?
					  <TableRow>
						  <TableCell colSpan={ Object.keys(Columns).length + 1}>
							  <Button
								className='cmd-object-table-add'
								color="secondary"
								size="small"
								endIcon={<Icon icon={plusFill} />}
								type="reset"
								variant="contained"
								disabled={!dataValid || innerData.length >= MaxRows}
								onClick={(e) => {
									if(dataIsArray()){
										let itm = [];
										Object.keys(Columns).forEach(v => {
											itm.push(null);
										});
										
										let newD = [...innerData,itm];
										setInnerData(newD);
										
										handleCellChange(null, null, null, newD);
									}else{
										let uid = t("Item") + (Object.keys(innerData).length + 1);
										let itm = {};
										
										if(PrimaryIdentifier){
											itm[PrimaryIdentifier] = uid 
										}
										
										Object.keys(Columns).forEach(v => {
											if(v.Default && v.Property){
												itm[v.Property] = v.Default;
											}
										});
										
										let newD = [...innerData,itm];
										setInnerData(newD);
										
										handleCellChange(null, null, null, newD);
									}
								}}
							>
								{t("Add")}
							</Button>
						</TableCell>
					</TableRow>
					  : null
				  }
				</TableFooter>
		      </Table>
            </TableContainer>
        </>
    );
}
