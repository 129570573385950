import countries from "./../countries.json";
import { useTranslation } from 'react-i18next';


export default function GlobalElements() {
	const { t } = useTranslation();
	
	return (
			<datalist id='global_country_list'>
				{
					Object.values(countries).map((item, key) => {
						return <option key={key} value={t(item.native_name)} />
					})	
				}
			</datalist>
	)
}

